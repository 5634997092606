import logo from './logo.svg';
import './App.css';

import React from 'react';

import { Route, Routes, Navigate, Router } from "react-router-dom";

import Home from './pages/home';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from './components/pageLoader';


function App() {

  const getRedirectURL = () => {
    const curPath = window.location.pathname;
    const allowed_paths = [
      "/",
      "/dashboard",
      "/members",
      "/subscriptions",
      "/transactions",
      "/communities",
      "/analytics",
      "/profile",
      "/purchases"
      // abhi_check if_any_urls_to_add
    ]
  
    var display = allowed_paths.includes(curPath) || curPath.startsWith("/edit/");

    if (display) {
      if (curPath.startsWith("/edit/")) {
        return "/communities";
      }
      return curPath;
    }
    return "/";
  
  }

  const { isLoading } = useAuth0();
  // if (isLoading) return <PageLoader />;
  return (
    // <Router>
    <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}${getRedirectURL()}`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      // redirect_uri: `${window.location.origin}`
    }}
  >
      <Home />
      </Auth0Provider>

    // </Router>
    
  );
}

export default App;
