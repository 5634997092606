import React, { useEffect, useState } from 'react';
import { Box, Text, DataTable, Spinner, Collapsible } from 'grommet';
import { StatusGood, StatusCritical, StatusWarning, Clock, FormClose } from 'grommet-icons';
import TransactionDetailsLayer from './transactionDetailsLayer';

const dummyData = Array.from({ length: 50 }, (_, i) => ({
  id: i + 1,
  amount: Math.floor(Math.random() * 1000),
  fee: Math.floor(Math.random() * 50),
  status: ['Processing', 'Withdrawn', 'Failed'][Math.floor(Math.random() * 3)],
  requestedAt: new Date().toISOString(),
  processedAt: Math.random() > 0.5 ? new Date().toISOString() : null,
  failureReason: Math.random() > 0.5 ? 'Insufficient funds' : 'Bank error',
}));

const fetchData = async (start, end) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dummyData.slice(start, end));
    }, 1000);
  });
};

const getStatusColor = (status) => {
    if (status === "Withdrawn" || status === "requested") return "darkgreen";
    if (status === "Failed") return "darkred";
    return "grey";
  };

const getStatusIcon = (status) => {
  if (status === 'Withdrawn') return <StatusGood color="status-ok" />;
  if (status === 'Failed') return <FormClose color="status-critical" />;
  return <Clock color="status-warning" />;
};

const getFormattedTime = (time) => {
    const timeOptions = {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          year: '2-digit',
          month: '2-digit',
          day: '2-digit'
        };
    const formattedDateTime = new Intl.DateTimeFormat('en-US', timeOptions).format(time);
    return formattedDateTime;
}

const dataColumns = [
    {
      property: 'amount',
      header: <Text>Amount</Text>,
      render: data => <Text>{data.amount}</Text>,
    },
    {
      property: 'requestedAt',
      header: <Text>Requested Date</Text>,
      render: data => <Text>{new Date(
        data.requestedAt
      ).toLocaleDateString("en-IN")}</Text>,
    // render: data => <Text>
    //     {/* {getFormattedTime(data)} */}
    // </Text>
    },
    {
      property: 'status',
      header: <Text>Status</Text>,
      primary: true,
      render: data => (
        <Box direction="row" align="center" gap="xsmall">
          {getStatusIcon(data.status)}
          <Text>{data.status}</Text>
        </Box>
      ),
    },
  ]

const TransactionHistory = ({transactions}) => {
//   const [transactions, setTransactions] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [loading, setLoading] = useState(false);

  const onRowClick = (transaction) => {
    setExpandedRow(transaction);
  };

  const closeLayer = () => {
    setExpandedRow(null);
  };

//   useEffect(() => {
//     setLoading(true);
//     fetchData(0, 10).then(data => {
//       setTransactions(data);
//       setLoading(false);
//     });
//   }, []);

//   const onMore = () => {
//     setLoading(true);
//     fetchData(transactions.length, transactions.length + 10).then(data => {
//       setTransactions(prev => [...prev, ...data]);
//       setLoading(false);
//     });
//   };


  return (
    <Box pad="medium" gap="small">
      <Box overflow="auto">
        <DataTable
          columns={dataColumns}
          data={transactions}
          step={10}
        //   onMore={transactions.length < 50 ? onMore : undefined}
          onClickRow={({ datum }) => onRowClick(datum)}
          rowProps={{
            Processing: { background: "#efefef" },
            Withdrawn: { background: '#d6fff1' },
            Failed: { background: '#ffefef' },
          }}
          pin="header"
        />
      </Box>
      {loading && (
        <Box align="center" pad="small">
          <Spinner />
        </Box>
      )}
      {expandedRow && (
        <TransactionDetailsLayer transaction={expandedRow} onClose={closeLayer} />
      )}
    </Box>
  );
};

export default TransactionHistory;
