import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./pageLoader";
import { Grommet, Box } from "grommet";
import { hpe } from "grommet-theme-hpe";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Grommet theme={hpe} full>
        <Box align="center" style={{ marginTop: "10%" }}>
          <PageLoader />
        </Box>
      </Grommet>
    ),
  });

  return <Component />;
};