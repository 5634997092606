import React, { useContext } from "react";
import {
  Button,
  Box,
  Grid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  ResponsiveContext,
} from "grommet";
import { useNavigate } from "react-router-dom";
// import NothingToShow from "./nothingToShow";

const bgmColours = [

  // ["rgb(225 255 253)", "rgb(1 229 214)"],
  ["rgb(212 235 255)", "rgb(82 166 240)"],
// 

    // ["rgb(199, 250, 232)", "rgb(23, 235, 160)"],
  // ["rgb(255, 239, 210)", "rgb(255, 188, 68)"],
  // ["rgb(239, 239, 239)", "rgb(203 203 203)"],

  
];

const GridStyledCards = ({ items, nothingToShowMsg }) => {
  
  if (!nothingToShowMsg) {
    nothingToShowMsg = "Oops, landed on Moon!";
  }

  const navigate = useNavigate();
  const screenSize = useContext(ResponsiveContext);

  const cardFullClickHandler = (item) => {
    // window.alert("Card anywhere Clicked");
    navigate(item.linkTo);
    
  };

  // const CardMainLaunchHandler = (subId) => {
  //   window.alert("Card Full Clicked");
  //   // const tmpName = "Sorry";

  //   // props.onCardLaunchClickNavigate(subId);
  // };

  const colourSelectorByIndex = (index) => {
    return bgmColours[index % bgmColours.length];
  };

  // console.log(
  //   "!(props.subs || props.subs.length > 0) in styled cards : ",
  //   props.subs
  // );
  if (!items || items.length == 0) {
    // console.log("returning true");
    return <Text>{nothingToShowMsg}</Text>;
  }



  const isMobile = screenSize === "small" || screenSize === "xsmall";

  // console.log("from styledCards nothingToShowMsg : ", props.nothingToShowMsg);
  // console.log("from styledCards subs : ", props.subs);
  return (
    <Grid
      gap="medium"
      {...(!isMobile && {columns : [
        ["medium", "auto"],
        ["medium", "auto"],
        ["medium", "medium"],
      ]})}
      // columns={{
      //   count: 2,
      //   // size: ["auto", "full"],
      // }}
      pad="medium"
      margin="small"
      style={{ flexWrap: "wrap", overflow : "auto" }}
    >
      {items.map((item, index) => {
        // const [bgColor, buttonColor] = colourSelectorByIndex(index);
        // console.log("bgColor, buttonColor : ", bgColor, buttonColor);
        
        return (
          !item.disabled && <Button                 onClick={() => {
            // console.log("onclick button in card : ", sub._id);
            cardFullClickHandler(item);
          }}>
          <Card
            key={index}
            style={{
              backgroundColor: colourSelectorByIndex(index)[0],
              // minWidth: "fit-content",
              // minHeight: "fit-content",
              // maxHeight: "100%",
              borderRadius: "12px",
              maxWidth: "355px",
            }}
            // height="medium"
            // width="medium"
            // height="260px"
          >
            <Box
              margin="0%"
              pad={{ vertical: "medium", horizontal: "medium" }}
              alignSelf="center"
              style={{
                minWidth: "fit-content",
                minHeight: "fit-content",
                marginBottom: "small",
              }}
            >
              <Box style={{ maxHeight: "48px", minHeight: "48px" }}>
                {item.name.length >= 55 ? (
                  <Text
                    weight="bold"
                    size="medium"
                    color="black"
                    tip={{ content: <Text>{item.name}</Text>, plain: false }}
                  >
                    {item.name.substring(0, 55)}...
                  </Text>
                ) : (
                  <Text
                    style={{ textOverflow: "ellipsis" }}
                    weight="bold"
                    size="medium"
                    color="black"
                    tip={{ content: <Text>{item.name}</Text>, plain: false }}
                  >
                    {item.name}
                  </Text>
                )}
              </Box>
            </Box>
            <Box pad={{ vertical: "none", horizontal: "medium" }}>
              <Text
                style={{ paddingBottom: "7%" }}
                weight="normal"
                size="xsmall"
                color="grey"
                alignSelf="center"
              >
                {item.name.toUpperCase()}
              </Text>
              {/* Assuming sample description as 2 TB, 10 instance, 5 nodes */}
              {/* Remove 5 nodes */}
              <Box>
                {item.description
                  .split(",")
                  .slice(0, 2)
                  .map((text) => (
                    <Text
                      weight="normal"
                      size="xsmall"
                      color="black"
                      alignSelf="center"
                    >
                      {text}
                    </Text>
                  ))}
              </Box>
            </Box>
            <CardFooter
              alignSelf="center"
              style={{ minWidth: "90%" }}
              pad={{ vertical: "medium", horizontal: "small" }}
            >
              <Button
                primary
                label="Launch"
                color={colourSelectorByIndex(index)[1]}
                style={{ minWidth: "100%" }}
                // onClick={() => {
                //   // console.log("onclick button in card : ", sub._id);
                //   CardMainLaunchHandler(item);
                // }}
              ></Button>
            </CardFooter>
          </Card>
          </Button>

        );
      })}
    </Grid>
  );
};

export default GridStyledCards;
