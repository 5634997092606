import React, { useState, useEffect } from "react";
import {
  Grommet,
  Box,
  Button,
  Layer,
  TextInput,
  Spinner,
  Text,
  Notification,
  Page,
  Form,
  FormField,
  CheckBox,
} from "grommet";
import NothingToShow from "./nothingToShow";
import { PageLoader } from "./pageLoader";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import displayOrderStatus from "./displayOrderStatus";
import DisplayOrderStatus from "./displayOrderStatus";
import {
  Checkmark,
  CircleInformation,
  Close,
  FormClose,
  Info,
  Norton,
  StatusGood,
  Tooltip,
} from "grommet-icons";

const theme = {
  global: {
    font: {
      family: "Arial",
      size: "18px",
      height: "20px",
    },
  },
};

const WithdrawFunds = ({ showLayer, setShowLayer }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [validatingVpa, setValidatingVpa] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const [VpaValidated, setVpaValidated] = useState(false);
  const [vpaName, setVpaName] = useState("");
  const [availableAmount, setAvailableAmount] = useState(null);
  const [requestForm, setRequestForm] = useState({
    amount: 0,
    upiId: "",
  });
  const [notification, setNotification] = useState(null);
  const [displayOrder, setDisplayOrder] = useState(false);
  const [orderStatus, setOrderStatus] = useState();

  const [withdrawalForm, setWithdrawalForm] = useState({
    availableAmountCombined: 0,
    totalAmountCombined: 0,
    upiId: "",
  });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (showLayer) {
      fetchAvailableAmount();
    }
  }, []);

  const fetchAvailableAmount = async () => {
    setIsLoading(true);
    // console.log("Need to fetch ");
    const getAmountsURL =
      process.env.REACT_APP_BACKEND_URL +
      "/api/admin/communityPayments" +
      "?combined=true";
    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: getAmountsURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    };

    // console.log("config to fetch ", config);

    try {
      const response = await axios.request(config);
      if (response) {
        if (response.status === 200) {
          const responseData = response.data;

          if (responseData?.upiId) {
            // console.log("passed responseData?.upiId : ", responseData?.upiId);
            await validateVpaFunction({ upiId: responseData?.upiId });
          } else {
            // console.log("passed requestForm.upiId  : ", requestForm.upiId);
            await validateVpaFunction({ upiId: requestForm.upiId });
          }

          setWithdrawalForm({
            availableAmountCombined: responseData?.availableAmount || 0,
            totalAmountCombined: responseData?.totalAmount || 0,
            upiId: responseData?.upiId || "",
          });
          setRequestForm({
            ...requestForm,
            upiId: requestForm.upiId || responseData?.upiId,
          });
          setNotification();
        }
      } else {
        throw new Error("No Response ERROR");
      }
    } catch (error) {
      // console.log(error);
      setNotification({
        status: "critical",
        message: "Server Error",
      });
      return "server_error";
    } finally {
      setIsLoading(false);
    }
  };

  const validateVpaFunction = async ({ upiId }) => {
    // console.log("upiId : ", upiId);
    if (!upiId) return;
    if (upiId === "") {
      setVpaValidated(false);
      return;
    }

    setValidatingVpa(true);
    // console.log("Need to validate ");
    const validateVPA =
      process.env.REACT_APP_BACKEND_URL +
      "/payments/phonepe/vpa/validate/" +
      upiId;
    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: validateVPA,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    };

    // console.log("config to fetch ", config);
    setVpaName("");
    try {
      const response = await axios.request(config);
      if (response && response.status === 200) {
        const responseData = response.data;
        const { code, data } = responseData;
        if (code === "SUCCESS") {
          setVpaValidated(true);
          setVpaName(data?.name || "");
        } else {
          setVpaValidated(false);
        }
      } else {
        setVpaValidated(false);
      }
    } catch (error) {
      // console.log(error);
      //   setNotification({
      //     status: "critical",
      //     message: "Invalid Upi Id",
      //   });
      setVpaValidated(false);
      return "server_error";
    } finally {
      setValidatingVpa(false);
    }
  };

  const handleRequestFormChange = (value) => {
    setRequestForm(value);
  };

  const upiIdValidation = [
    {
      regexp: new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$"),
      message: "Enter a valid UPI id",
      status: "error",
    },
    // Cannot pass any async function here
  ];

  //   only allow positive numbers
  const amountValidation = [
    {
      regexp: new RegExp("^[1-9][0-9]*$"),
      message: "Enter a valid amount",
      status: "error",
    },
    () => {
      if (requestForm.amount > withdrawalForm.availableAmountCombined) {
        return "Try smaller amount";
      }
      return;
    },
    // Cannot pass any async function here
  ];

  const handleWithdraw = async () => {
    if (
      requestForm.amount <= 0 ||
      requestForm.amount > withdrawalForm.availableAmountCombined
    ) {
      showNotification("Invalid withdrawal amount", "critical");
      return;
    }
    // console.log("VpaValidated : ", VpaValidated);
    if (!VpaValidated) return;
    // Make a request here

    setButtonLoading(true);
    const getAmountsURL =
      process.env.REACT_APP_BACKEND_URL + "/api/admin/transactions";

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: getAmountsURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
      data: requestForm,
    };

    // console.log("config to fetch ", config);

    // Show the order status and close the layer along with it in 5 seconds
    const closeTime = 4000;
    try {
      const response = await axios.request(config);
      if (response && response.status === 200) {
        const responseData = response.data;
        setOrderStatus(true);
      } else {
        throw new Error("No response or failed");
      }
    } catch (error) {
      setOrderStatus(false);
      // console.log("error in withdrawal request", error);
    } finally {
      setButtonLoading(false);
      setDisplayOrder(true);
      setTimeout(() => {
        setShowLayer(false);
      }, closeTime);
    }
    return;
    // try {
    //     await axios.post('/api/withdraw', { adminId: 'ADMIN_ID', amount });
    //     showNotification("Amount will be sent by EOD", "status-ok");
    //     setAvailableAmount(prevAmount => prevAmount - amount);
    //     setAmount('');
    // } catch (error) {
    //     showNotification(error.response?.data?.error || "Error processing withdrawal", "status-critical");
    // }
  };

  const showNotification = (message, status) => {
    setNotification({ message, status });
    setTimeout(() => setNotification(null), 5000);
  };

  return (
    <Page>
      {!displayOrder && showLayer && (
        <Layer
          onEsc={() => setShowLayer(false)}
          onClickOutside={() => setShowLayer(false)}
          style={{ overflow: "auto" }}
        >
          {notification && (
            <Notification
              // toast
              status={notification.status}
              title={notification.message}
              onClose={() => setNotification(null)}
            />
          )}
          <Box pad="medium" width="medium">
            {isLoading ? (
              <Box align="center" justify="center" height="small">
                <PageLoader />
              </Box>
            ) : // message="Aww Snap, Try Later"
            withdrawalForm.availableAmountCombined === null ? (
              <NothingToShow message={withdrawalForm.availableAmount} />
            ) : (
              <Box gap="medium">
                <Box gap="xsmall">
                  <Text>
                    Available Amount:{" "}
                    {withdrawalForm.availableAmountCombined.toLocaleString(
                      "en-IN",
                      {
                        style: "currency",
                        currency: "INR", // Currency code for Indian Rupees
                        maximumFractionDigits: 2, // Maximum number of decimal places
                      }
                    )}
                  </Text>
                  <Text size="xsmall">
                    Money collected from all of your communities
                  </Text>
                </Box>

                <Form
                  onSubmit={handleWithdraw}
                  value={requestForm}
                  onChange={handleRequestFormChange}
                  validate="submit"
                >
                  <FormField name="amount" validate={amountValidation}>
                    <TextInput
                      name="amount"
                      placeholder="Enter amount to withdraw"
                    />
                  </FormField>
                  <FormField
                    name="upiId"
                    validate={upiIdValidation}
                    error={VpaValidated ? "" : "Invalid upi id."}
                    onChange={async (e) => {
                      setVpaValidated(false);
                      await validateVpaFunction({ upiId: e.target.value });
                    }}
                  >
                    <TextInput
                      name="upiId"
                      placeholder="upi"
                      icon={
                        validatingVpa ? (
                          <Spinner
                            border={[
                              {
                                side: "all",
                                color: "brand",
                                size: "medium",
                                style: "dotted",
                              },
                            ]}
                          />
                        ) : VpaValidated ? (
                          <StatusGood style={{ stroke: "green" }} />
                        ) : (
                          <Close style={{ stroke: "red" }} />
                        )
                      }
                    />
                  </FormField>
                  <Text size="small" color="darkgreen">
                    {" "}
                    {vpaName}{" "}
                  </Text>
                  <Box direction="row-responsive">
                    <Button
                      type="cancel"
                      label="Cancel"
                      onClick={() => setShowLayer(false)}
                    />
                    <Button
                      type="submit"
                      label="Request Withdrawal"
                      secondary
                      busy={buttonLoading}
                    />
                  </Box>
                </Form>
              </Box>
            )}
          </Box>
        </Layer>
      )}
      {displayOrder && <DisplayOrderStatus status={orderStatus} />}
    </Page>
  );
};

export default WithdrawFunds;
