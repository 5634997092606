import { Box, Text } from "grommet";
import React, { useState } from "react";

const ColorPicker = ({color, setColor, label}) => {
    // const [color, setColor] = useState();
  
    // console.log("colorPicker", color);
  
    return (
        <Box align="center" gap="small">
            <Text>{label}</Text>
      <input type="color" value={color} onChange={e => setColor(e.target.value)} placeholder="text"/>
        </Box>
    );
}
export default ColorPicker;