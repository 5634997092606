import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Form,
  FormField,
  TextInput,
  Select,
  Button,
  TextArea,
  CheckBox,
  ResponsiveContext,
  Text,
  Tip,
} from "grommet";
import { Add, CircleInformation, Info, Trash } from "grommet-icons";

function generateUniqueId(length = 16) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uniqueId = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueId += characters[randomIndex];
  }
  return uniqueId;
}
const planTypeOptions = ["Events", "Donation"]
const currencyOptions = ["INR ₹"];
const periodOptions = [
  "One Time"];
const pointMaxLength = 200;

const SubscriptionForms = ({
  formRef,
  plans,
  setPlans,
  fecthedPageDetails,
}) => {
  const size = useContext(ResponsiveContext);
  // console.log("SIZEEEE", size);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Initialize availableSeats to totalSeats for each plan
    const initializedPlans = plans.map((plan) => ({
      ...plan,
      availableSeats: plan.totalSeats || 0,
      remDiscountSeats: plan.discountMembers || 0,
    }));
    setPlans(initializedPlans);
  }, [plans.length]);

  const handlePlanNameChange = (index, value) => {
    setErrors({});
    const updatedPlans = [...plans];
    updatedPlans[index].planName = value;
    setPlans(updatedPlans);
  };

  const handleCurrencyChange = (index, value) => {
    setErrors({});
    const updatedPlans = [...plans];
    updatedPlans[index].currency = value;
    setPlans(updatedPlans);
  };

  const handleplanTypeChange = (index, value) => {
    setErrors({});
    const updatedPlans = [...plans];
    updatedPlans[index].planType = value;
    if (updatedPlans[index].planType === "Donation") {
       updatedPlans[index].price = 0;
     }
    setPlans(updatedPlans);
  };

  const handlePriceChange = (index, value) => {
    try {
      // if() return;
      if(!value || value === "") value = "0";
      const intValue = parseInt(value);
      if(intValue < 0) return;
      const updatedPlans = [...plans];
    // const regex = /^(0|[1-9]\d*)$/;

    // if (!regex.test(value)) {
    // console.log("Valid value:", intValue, index);
    //   return;
    //   // Add your logic for valid values here
    // }
    // if (value < 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [`price${index}`]: "Price should be greater than or equal to 0",
    //   }));
    //   return;
    // } else {
    //   setErrors((prevErrors) => {
    //     const updatedErrors = { ...prevErrors };
    //     delete updatedErrors[`price${index}`];
    //     return updatedErrors;
    //   });
    updatedPlans[index].price = intValue;
    // }
    if (
     updatedPlans[index].price === 0
    ) {
      updatedPlans[index].discountPercentage = 0;
      updatedPlans[index].discountMembers = 0;
    }
    setPlans(updatedPlans);
      
    } catch (error) {
      
    }
    
  };

  const handlePeriodChange = (index, value) => {
    setErrors({});
    const updatedPlans = [...plans];
    updatedPlans[index].period = value;
    setPlans(updatedPlans);
  };

  const handlePointChange = (planIndex, pointIndex, value) => {
    setErrors({});
    const updatedPlans = [...plans];
    updatedPlans[planIndex].points[pointIndex] = value;
    setPlans(updatedPlans);
  };

  const handleSeatsAvailableToggle = (index, value) => {
    const updatedPlans = [...plans];
    updatedPlans[index].seatsAvailable = value;
    setPlans(updatedPlans);
  };

  const isPlanorCommFirstTime = (index = 0) => {
    if (
      fecthedPageDetails?.plans &&
      typeof fecthedPageDetails?.plans === "object"
    ) {
      if (
        fecthedPageDetails?.plans.length >= index + 1 &&
        fecthedPageDetails?.plans[index]?.availableSeats
      ) {
        // Add the difference
        // window.alert("Add the difference");
        return false;
      } else {
        // First time, so add as the total
        // updatedPlans[index].availableSeats = value;
        return true;
      }
    } else {
      console.error("Invalid : fetched details");
      return undefined;
    }
  };

  const handleTotalSeatsChange = (index, value) => {
    var intValue = 0;
    try {
      if(!value || value === "") value = "0";
      intValue = parseInt(value, 10);
      if(intValue < 0) return;
    } catch (error) {
      return;
    }
    // console.log("intValue @ handleTotalSeatsChange : ", intValue);
    const updatedPlans = [...plans];
    updatedPlans[index].totalSeats = intValue;
    // console.log("fecthedPageDetails : ", fecthedPageDetails);
    if (
      fecthedPageDetails?.plans &&
      typeof fecthedPageDetails?.plans === "object"
    ) {
      if (
        fecthedPageDetails?.plans.length >= index + 1 &&
        fecthedPageDetails?.plans[index]?.availableSeats
      ) {
        // Add the difference
        window.alert("Add the difference");
      } else {
        // First time, so add as the total
        updatedPlans[index].availableSeats = intValue;
      }
    } else {
      window.alert("Invalid fetched details");
    }
    setPlans(updatedPlans);

    // if (
    //   updatedPlans[index].availableSeats === "" ||
    //   updatedPlans[index].availableSeats === "0"
    // ) {

    // }
  };

  const handleDiscountToggle = (index, value) => {
    const updatedPlans = [...plans];
    updatedPlans[index].discountAvailable = value;
    setPlans(updatedPlans);
  };

  const handleDiscountPercentageChange = (index, value) => {
    const updatedPlans = [...plans];
    const parsedValue = parseInt(value, 10);

    if (parsedValue < 1 || parsedValue > 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`discountPercentage${index}`]:
          "Value should be more than 1 and less than or equal to 100",
      }));
    } else {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[`discountPercentage${index}`];
        return updatedErrors;
      });
      updatedPlans[index].discountPercentage = value;

      if (
        parsedValue === 100 &&
        updatedPlans[index].totalSeats === updatedPlans[index].discountMembers
      ) {
        updatedPlans[index].price = 0;
        updatedPlans[index].currency = "INR ₹";
      }
    }

    setPlans(updatedPlans);
  };

  const handleDiscountMembersChange = (index, value) => {
    
    var intValue  = 0;
    try {
      if(!value || value === "") value = "0";
      intValue = parseInt(value); 
      // console.log("Intvalue @ handleDiscountMembersChange : ", intValue);

    } catch (error) {
      return;
    }

    if(intValue < 0) return;
    const updatedPlans = [...plans];
    if (intValue > updatedPlans[index].totalSeats) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`discountMembers${index}`]:
          "Number of discount members should not exceed the total number of members/seats",
      }));
      return;
    } else {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        // delete updatedErrors[`discountMembers${index}`];
      });
      updatedPlans[index].discountMembers = intValue;

      if (
        updatedPlans[index].discountPercentage === "100" &&
        updatedPlans[index].totalSeats === intValue
      ) {
        updatedPlans[index].price = 0;
        updatedPlans[index].currency = "INR ₹";
      }
    }
    setPlans(updatedPlans);

  };

  const addPlan = () => {
    setErrors({});
    if (plans.length < 3) {
      const newPlan = {
        planObjectId: generateUniqueId(),
        planType: "Event",
        planName: "",
        currency: "INR ₹",
        price: 0,
        period: "One Time",
        points: [""],
        seatsAvailable: false,
        totalSeats: 0,
        availableSeats: 0,
        discountAvailable: false,
        discountPercentage: 1,
        discountMembers: 0,
      };

      // console.log("Adding new plan:", newPlan);
      setPlans([...plans, newPlan]);
    } else {
      setErrors({ maxPlans: "Maximum of 3 plans allowed." });
    }
  };

  const removePlan = (index) => {
    const updatedPlans = [...plans];
    updatedPlans.splice(index, 1);
    setPlans(updatedPlans);
    setErrors({});
  };

  const addPoint = (planIndex) => {
    const updatedPlans = [...plans];
    if (updatedPlans[planIndex].points.length < 5) {
      updatedPlans[planIndex].points.push("");
      setPlans(updatedPlans);
    } else {
      setErrors({ maxPoints: "Maximum of 5 points allowed per plan." });
    }
  };

  const removePoint = (planIndex, pointIndex) => {
    const updatedPlans = [...plans];
    updatedPlans[planIndex].points.splice(pointIndex, 1);
    setPlans(updatedPlans);
    setErrors({});
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    // Check if number of discount members is not greater than the total number of members/seats
    let hasError = false;
    plans.forEach((plan, index) => {
      if (plan.discountMembers > plan.totalSeats) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`discountMembers${index}`]:
            "Number of discount members should not exceed the total number of members/seats",
        }));
        hasError = true;
      }
    });
    if (hasError) return;
    // Your controlled form submission logic here (e.g., using formRef)
  };

  return (
    <Box align="center" pad="medium" justify="center" overflow="auto">
      <Box direction="row" justify="evenly" align="center">
        <Form
          validate="change"
          errors={errors}
          value={{ plans }}
          onSubmit={handleSubmit}
        >
          <Box direction="row" wrap>
            {plans.map((plan, index) => (
              <Box
                key={index}
                margin={{ right: "medium", bottom: "medium" }}
                border="all"
                pad="medium"
                width="medium"
              >
                 <FormField label="Event Type">
                  <Select
                    required
                    options={planTypeOptions}
                    value={plan.planType}
                    style={{ fontWeight: "normal" }}
                    onChange={({ option }) =>
                     handleplanTypeChange(index, option)
                    }
                    // disabled={
                    //   plan.discountPercentage === "100" &&
                    //   plan.totalSeats === plan.discountMembers
                    // }
                  />
                </FormField>

                <Box direction="row" align="center" justify="between">
                  <FormField
                    label={`Plan ${index + 1} Name`}
                    name={`plans[${index}][planName]`}
                    error={errors?.maxPlans}
                    // error={errors?.maxPlans || errors["planName" + index]}
                  >
                    <TextInput
                      required
                      style={{ fontWeight: "normal" }}
                      maxLength={150}
                      name={`plans[${index}][planName]`}
                      value={plan.planName}
                      onChange={(event) =>
                        handlePlanNameChange(index, event.target.value)
                      }
                      validate={[
                        (value) => {
                          if (!value || value === "") return "Needed";
                        },
                      ]}
                    />
                  </FormField>
                  {index !== 0 && (
                    <Button
                      icon={<Trash />}
                      onClick={() => removePlan(index)}
                    />
                  )}
                </Box>
                <FormField label="Currency">
                  <Select
                    required
                    options={currencyOptions}
                    value={plan.currency}
                    style={{ fontWeight: "normal" }}
                    onChange={({ option }) =>
                      handleCurrencyChange(index, option)
                    }
                    disabled={
                      plan.discountPercentage === "100" &&
                      plan.totalSeats === plan.discountMembers
                    }
                  />
                </FormField>
                <FormField
                  label="Price"
                  // error={errors.maxPlans || errors[`price${index}`]}
                  name={`plans[${index}][price]`}
                  // value={plan.price}
                  //   only allow positive numbers
                  // value={plan.price}
                  // validate={[
                  //   // {
                  //   //   regexp: new RegExp("^(0|[1-9]\d*)$"),
                  //   //   message: "Enter a valid amount",
                  //   //   status: "error",
                  //   // },
                  //   (value) => {
                  //     console.log("price from plan : ", value);
                  //     // window.alert(value);
                  //     if(value === 0) return null;
                  //     else return "invalid";
                  //   }
                  // ]}
                  disabled={
                    plan.planType=="Donation"
                  }
                  onChange={(event) => {
                    handlePriceChange(index, event.target.value);
                  }}
                >
                  <TextInput
                    required
                    style={{ fontWeight: "normal" }}
                    maxLength={10}
                    name={`plans[${index}][price]`}
                    value={plan?.price}
                    // onChange={(event) =>

                    // }
                    disabled={
                      (plan.discountPercentage === "100" &&
                      plan.totalSeats === plan.discountMembers) || (plan.planType=="Donation")
                    }
                    // onFocus={() =>
                    //   setErrors((prevErrors) => ({
                    //     ...prevErrors,
                    //     [`price${index}`]: "",
                    //   }))
                    // }
                  />
                </FormField>
                <FormField label="Period">
                  <Select
                    required
                    options={periodOptions}
                    value={plan.period}
                    style={{ fontWeight: "normal" }}
                    onChange={({ option }) => handlePeriodChange(index, option)}
                  />
                </FormField>
                <Box margin={{ top: "small" }}>
                  {plan.points.map((point, pointIndex) => (
                    <Box key={pointIndex} margin={{ bottom: "small" }}>
                      <FormField
                        label={`Point ${pointIndex + 1}`}
                        name={`plans[${index}][points][${pointIndex}]`}
                        // error={errors[`points${index}${pointIndex}`]}
                      >
                        <TextArea
                          required
                          style={{ fontWeight: "normal" }}
                          value={point}
                          onChange={(event) =>
                            handlePointChange(
                              index,
                              pointIndex,
                              event.target.value
                            )
                          }
                          maxLength={pointMaxLength}
                        />
                      </FormField>
                      {pointIndex !== 0 && (
                        <Button
                          icon={<Trash />}
                          margin={{ top: "small" }}
                          onClick={() => removePoint(index, pointIndex)}
                        />
                      )}
                    </Box>
                  ))}
                  {plan.points.length < 5 && (
                    <Button
                      onClick={() => addPoint(index)}
                      icon={<Add />}
                      label="Add Point"
                    />
                  )}
                </Box>
                {/* Seats Available Checkbox and Input */}
                <Box margin={{ top: "medium" }}>
                  {/* <CheckBox
                    label={
                      <Box direction="row-responsive" gap="small" align="base">
                        <Text>Limit Members/Seats</Text>  
                      </Box>
                    }
                    checked={plan.seatsAvailable}
                    disabled={isPlanorCommFirstTime(index) !== true}
                    onChange={(event) =>
                      handleSeatsAvailableToggle(index, event.target.checked)
                    }
                  /> */}
                  { (
                    <Box> 
                      <FormField
                        label={
                          <Box direction="row-responsive" gap="small" align="base">
                            <Text>Total Members/Seats</Text>  
                            <Tip content="Once saved, cannot be changed. You can apply discounts ">
                          <CircleInformation />
                        </Tip>
                          </Box>
                        }
                        // error={errors[`totalSeats${index}`]}
                        name={`plans[${index}][totalSeats]`}
                        disabled={isPlanorCommFirstTime(index) !== true}
                        onChange={(event) => {
                          handleTotalSeatsChange(index, event.target.value);
                        }}
                      >
                        
                        <TextInput
                          required
                          // type="number"
                          name={`plans[${index}][totalSeats]`}
                          value={plan.totalSeats}
                          // onChange={(event) =>
                          //   handleTotalSeatsChange(index, event.target.value)
                          // }
                          onFocus={() =>
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              [`totalSeats${index}`]: "",
                            }))
                          }
                          // disabled={true}
                          disabled={isPlanorCommFirstTime(index) !== true}
                        />
                      </FormField>
                      {plan.totalSeats !== "" && (
                        <Box margin={{ top: "small" }}>
                          <TextInput
                            plain
                            readOnly
                            value={`Available Members/Seats: ${plan.availableSeats}`}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
                {/* Discount Available Checkbox and Inputs */}
                <Box margin={{ top: "medium" }}>
                  <CheckBox
                    disabled={isPlanorCommFirstTime(index) !== true || plan?.price ==0}
                    label={
                      <Box direction="row-responsive" gap="small" align="base">
                        <Text>Apply Discount</Text>
                        <Tip content="Once saved, cannot be changed">
                          <CircleInformation />
                        </Tip>
                      </Box>
                    }
                    checked={plan.discountAvailable}
                    onChange={(event) =>
                      handleDiscountToggle(index, event.target.checked)
                    }
                  />
                  {plan.discountAvailable && (
                    <>
                      <FormField
                        label="Discount Percentage"
                        // error={errors[`discountPercentage${index}`]}
                        disabled={isPlanorCommFirstTime(index) !== true || plan?.price ==0}
                      >
                        <TextInput
                          required
                          disabled={isPlanorCommFirstTime(index) !== true || plan?.price ==0}
                          // type="number"
                          value={plan.discountPercentage}
                          onChange={(event) =>
                            handleDiscountPercentageChange(
                              index,
                              event.target.value
                            )
                          }
                          onFocus={() =>
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              [`discountPercentage${index}`]: "",
                            }))
                          }
                          max={100}
                          min={1}
                        />
                      </FormField>
                      <FormField
                        disabled={isPlanorCommFirstTime(index) !== true || plan?.price ==0}
                        label="Number of Members for Discount"
                        // error={errors[`discountMembers${index}`]}
                      >
                        <TextInput
                          required
                          disabled={isPlanorCommFirstTime(index) !== true || plan?.price ==0}
                          // type="number"
                          value={plan.discountMembers}
                          onChange={(event) =>
                            handleDiscountMembersChange(
                              index,
                              event.target.value
                            )
                          }
                          onFocus={() =>
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              [`discountMembers${index}`]: "",
                            }))
                          }
                        />
                      </FormField>
                    </>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          {size === "xsmall" && plans.length < 3 && (
            <Button icon={<Add />} label="Add Plan" onClick={addPlan} />
          )}
        </Form>
        {size !== "xsmall" && plans.length < 3 && (
          <Button icon={<Add />} label="Add Plan" onClick={addPlan} />
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionForms;
