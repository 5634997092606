// SearchComponent.js
import React, { useState, useEffect, useContext } from 'react';
import { Grommet, Box, TextInput, Button, Grid, Text, Page, ResponsiveContext } from 'grommet';
import { Search } from 'grommet-icons';
import { grommet } from 'grommet/themes';
import axios from 'axios';





const SearchComponent = () => {
    const [productsFetched, setProductsFetched] = useState([]);
  const [products, setProducts] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [query, setQuery] = useState('');

  const size = useContext(ResponsiveContext);  // screen size
  const isMobile = size === "xsmall";

//   const fetchProducts = async () => {
//     // Dummy API call. Replace with your actual API call.
//     return [
//       { name: 'Product 1', nameLink: '/link1' },
//       { name: 'Product 2', nameLink: '/link2' },
//       { name: 'Product 3', nameLink: '/link3' },
//     ];
//   };

  const fetchProducts = async () => {
    setIsPageLoading(true);
    
  
    const paymentURL =
      process.env.REACT_APP_BACKEND_URL +
      "/api/communities";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: paymentURL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      if (!response || response.status !== 200) {
        throw new Error("No Response or not a valid one");
        }
        setProductsFetched(response.data);
      setProducts(response.data);
    } catch (error) {
      // console.log(error);
    }
    finally{
        setIsPageLoading(false);
        
    }
  };

  const searchHandler = (event) => {
    const query = event.target.value;
    setQuery(query);
    const filteredProducts = productsFetched.filter(product =>
      product.name.toLowerCase().includes(query.toLowerCase())
    );
    // console.log("filteredProducts : ", filteredProducts);
    setProducts(filteredProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Page flex>
      <Box align="center" pad="medium">
        <Box direction="row" width="medium" margin={{ bottom: 'medium' }} border>
          <TextInput
            value={query}
            icon={<Search />}
            plain
            placeholder="Search Community Products"
            onChange={searchHandler}
          />
        </Box>
        <Grid
          columns={{ count: isMobile ? 1 : 3, size: ['small', 'medium'] }}
          gap="medium"
        >
          {products.map(product => (
            <Box
              key={product.nameLink}
              border
              pad="small"
              round="small"
              background="light-1"
              align="center"
              skeleton={isPageLoading && {type: 'fadeIn'}}
            >
              <Text>{product.name}</Text>
              <Button
                label="Purchase"
                href={product.nameLink}
                size={isMobile ? "xsmall" : 'medium'}
                secondary
                margin={{ top: 'small' }}
              />
            </Box>
          ))}
        </Grid>
      </Box>
      </Page>
  );
};

export default SearchComponent;
