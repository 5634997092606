import React from 'react';
import { Grommet, Box, Text, Heading } from 'grommet';
import { StatusCritical, StatusGood, StatusWarning } from 'grommet-icons';

const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Arial',
      size: '18px',
      height: '20px',
    },
  },
};

const styles = {
  tagStyle: {
    display: 'inline-block',
    padding: '2px 10px',
    border: '2px solid red',
    color: 'red',
    fontWeight: 'bold',
    transform: 'rotate(-10deg)',
    fontFamily: 'Courier New, Courier, monospace',
    textTransform: 'uppercase',
    margin: '5px',
  },
  theaterBoard: {
    display: 'inline-block',
    padding: '5px 15px',
    border: '3px solid #FFD700',
    color: '#FFD700',
    background: 'black',
    fontFamily: 'Monaco, monospace',
    textTransform: 'uppercase',
    margin: '5px',
  },
  modernTag: {
    display: 'inline-block',
    padding: '2px 10px',
    border: '2px solid #0000FF',
    color: '#0000FF',
    fontWeight: 'bold',
    fontFamily: 'Arial, sans-serif',
    textTransform: 'uppercase',
    margin: '5px',
  },
  soldOutStyle: {
    display: 'inline-block',
    padding: '2px 10px',
    border: '2px solid red',
    color: 'red',
    fontWeight: 'bold',
    transform: 'rotate(-10deg)',
    fontFamily: 'Courier New, Courier, monospace',
    textTransform: 'uppercase',
    margin: '5px',
  },
};

const SeatAvailability = ({ seatsLeft = 19}) => {
  let messages = [];

  if (seatsLeft >= 25) {
    messages.push(
      <Box key="ok" direction="row" gap="small" align="center">
        {/* <StatusGood color="status-ok" /> */}
        <Text color="status-ok">{`${seatsLeft} seats left`}</Text>
      </Box>
    );
  } else if (seatsLeft > 10 && seatsLeft < 25) {
    messages.push(
      <Box key="warning" direction="row" gap="small" align="center">
        {/* <StatusWarning color="status-warning" /> */}
        <Text color="status-warning">
          <span style={styles.modernTag}>Few Left!</span> {seatsLeft} seats left
        </Text>
      </Box>
    );
  } else if (seatsLeft > 0 && seatsLeft <= 10) {
    messages.push(
      <Box key="critical" direction="row" gap="small" align="center">
        {/* <StatusCritical color="status-critical" /> */}
        <Text color="status-critical">
          <span style={styles.modernTag}>Few Left!</span> {seatsLeft} seats left
        </Text>
      </Box>
    );
  } else {
    messages.push(
      <Box key="soldout" direction="row" gap="small" align="center">
        {/* <StatusCritical color="status-critical" /> */}
        <Text color="status-critical">
          <span style={styles.soldOutStyle}>Sold Out</span>
        </Text>
      </Box>
    );
  }

  return (
    <Grommet theme={theme}>
      <Box align="center" justify="center" gap="medium">
        {messages.map((msg, index) => (
          <Box key={index} align="center" justify="center">
            {msg}
          </Box>
        ))}
      </Box>
    </Grommet>
  );
};

const App = () => (
  <Grommet theme={theme}>
    <Box fill align="center" justify="center">
      {/* Replace 18 with the number of seats left */}
      <SeatAvailability seatsLeft={18} />
    </Box>
  </Grommet>
);

export default SeatAvailability;
