// Footer.js
import React, { useContext, useState } from 'react';
import { Box, Text, Button, Anchor, ResponsiveContext, Layer } from 'grommet';
import { Mail, Phone } from 'grommet-icons';


const phoneNumbers = ["8073892471", "9743929613"];
const email = "support@membeed.com";

const PhoneEmailPopup = ({onClose }) => {

  return (
      <Layer
        onEsc={onClose}
        onClickOutside={onClose}
        position="center"
        modal
        plain
      >
        <Box
          pad="medium"
          gap="small"
          width="medium"
          background="light-2"
          round="small"
        >
          <Text weight="bold" size="large">
            Contact Information
          </Text>
          
          <Box pad="small">
            <Text size="medium" weight="bold">
              Phone Numbers:
            </Text>
            {phoneNumbers.map((number, index) => (
              <Box key={index} direction="row" align="center" gap="small">
                <Anchor href={`tel:${number}`} icon={<Phone color="brand" />} label={number} />
              </Box>
            ))}
          </Box>

          <Box pad="small">
            <Text size="medium" weight="bold">
              Email:
            </Text>
            <Box direction="row" align="center" gap="small">
              <Mail color="brand" />
              <Anchor href={`mailto:${email}`} label={email} />
            </Box>
          </Box>

          <Box direction="row" justify="end" gap="small" pad={{ top: 'small' }}>
            <Button label="Close" onClick={onClose} />
          </Box>
        </Box>
      </Layer>
  );
};

const Footer = () => {
  const size = useContext(ResponsiveContext);
  const isMobile = size === "xsmall";
  const textSize = isMobile ? "14px" : "xsmall";
  const [openSupport, setOpenSupport] = useState(false);
  return (
  <ResponsiveContext.Consumer>
    {size => (
      <Box>
        <Box
          direction={size === 'xsmall'  ? 'column' : 'row'}
          align="center"
          justify="center"
          background={{color : isMobile  ? 'brand' : 'white' }}
          pad={{ vertical: isMobile  ? 'large' : 'none' }}
          margin={{ top: isMobile  ? 'large' : 'small' }}
          // style={{ height: '40px', textAlign: 'center' }}
          gap={isMobile  ? 'xsmall' : 'small' }
        >
          <Anchor href="https://membeed.com" label="Powered By © 2024 Membeed.com" size={textSize} style={{textDecoration: "none"}}/>
          {!isMobile && <Text size={textSize}>|</Text>}
          <Anchor href="/policies/privacy" label="Privacy Policy" size={textSize} />
          {!isMobile && <Text size={textSize}>|</Text>}
          <Anchor href="/policies/refund" label="Refund Policy" size={textSize} />
          {!isMobile && <Text size={textSize}>|</Text>}
          <Anchor href="/policies/tnc" label="Terms of Use" size={textSize} />
          {!isMobile && <Text size={textSize}>|</Text>}
        <Anchor label="Support" size={textSize} onClick={()=>{setOpenSupport(true)}} style={{textDecoration: "none"}} />
        {!isMobile && <Text size={textSize}>|</Text>}
        <Anchor href="mailto:feedback@membeed.com" label="Feedback" size={textSize} style={{textDecoration: "none"}} />
        </Box>

        {/* Support pop up */}
        {openSupport && 
        <PhoneEmailPopup onClose={()=>{setOpenSupport(false)}}/>}
        {/* <Box
          pad="small"
          justify="between"
          align="center"
          direction="row"
          gap="large"
          fill
        >
          <Text size="small" color="#031635" textAlign="center">
            Powered By © 2024 Membeed.com
          </Text>
          <Button
            size="small"
            color="#031635"
            textAlign="center"
            href="mailto:simplyitsabg@gmail.com"
          >
            Feedback
          </Button>
        </Box> */}
      </Box>
    )}
  </ResponsiveContext.Consumer>
)};

export default Footer;
