import {
  Anchor,
  Box,
  Button,
  DataTable,
  Grommet,
  Header,
  Page,
  PageHeader,
  Heading,
  ResponsiveContext,
  Table,
  TableHeader,
  TableRow,
  Text,
} from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
import { Navigate, useNavigate } from "react-router-dom";
import NotFoundPage from "../components/notFound";
import NothingToShow from "../components/nothingToShow";
import AskCommNameLayer from "../components/popUpAskCommName";
import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { PageLoader } from "../components/pageLoader";
import { List } from "grommet-icons";
import { CommunityTable } from "../components/communityTable";

const CommunitiesPage = ({ commId }) => {
  // If there is no commID/communitiesPage ask them to create one
  const { getAccessTokenSilently } = useAuth0();
  const screenSize = useContext(ResponsiveContext);  // screen size

  const [userComms, setUserComms] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const getUserCommsAPI = async (silentLoad=false) => {
    silentLoad = silentLoad === true ? true : false;
    if(!silentLoad){setIsPageLoading(true);}
    let data = JSON.stringify({
      query: "status",
    });

    const adminURL =
      process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_ADMIN_SUBURL;

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: adminURL + "/sample@membeed.com/communities",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }
        const response_json = response.data;
        if (!response_json) {
          throw new Error("invalid data fetched");
        }
          // console.log("UserComms fetched : ", response_json);
          if (Array.isArray(response_json) && response_json.length === 0) {
              setIsAskNameOpen(true);
          }
        setUserComms(response_json);
        if(!silentLoad){
        setIsPageLoading(false);}
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        if(!silentLoad){
        setIsPageLoading(false);}
      });
  };

  // abhi_BACKEND_API

  //   const comms = [];
  const contentTitle = "Communities";
  const nothingToShowMsg = "Start by creating a community";
  const pageButtonLabel = screenSize === "small" || screenSize === "xsmall" ? "Create" : "Create a Community";
 
  const [isAskNameOpen, setIsAskNameOpen] = useState(false);

    const createCommunityHandler = () => {
        // console.log("Create Comm clicked");
    setIsAskNameOpen(true);
  };

  const navigate = useNavigate();

  const anchorClickHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    getUserCommsAPI();
  }, []);

  if (isPageLoading) return <PageLoader />;

  return (
    <Page flex>
      <Box direction="row"></Box>
      <PageHeader
        title={contentTitle}
        pad="medium"
        // pad={{ vertical: "medium", horizontal: "small" }}
        // subtitle="A subtitle for the page."
        parent={<Anchor label="Back" onClick={anchorClickHandler} />}
        actions={
          <Button
            // primary
            label={pageButtonLabel}
            color="#031635"
            onClick={createCommunityHandler}
            //     onClick={() => {
            //         getUserCommsAPI();
            //         console.log("Rendered comms : ", userComms);
            // }} //abhi_testing
            style={{
              background: "#5793f4",
            //   width: "100%",
              letterSpacing: "1px",
            }}
          />
        }
      />

      {!userComms || userComms.length == 0 ? (
        <NothingToShow message={nothingToShowMsg} />
      ) : (
        <Box margin="large">
          <CommunityTable communityList={userComms} getUserCommsAPI={getUserCommsAPI}/>
        </Box>
      )}
      {(isAskNameOpen ) && (
        // <Text>isAskNameOpen {isAskNameOpen}</Text>
        <AskCommNameLayer setIsAskNameOpen={setIsAskNameOpen} />
      )}
    </Page>
  );
};

export default CommunitiesPage;
