import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, ResponsiveContext, Text } from "grommet";

const LoginButton = ({label}) => {
  const { loginWithRedirect,  } = useAuth0();
  const size = useContext(ResponsiveContext);  // screen size
  const isMobile = size === "xsmall";

    
    return (<Button onClick={() => loginWithRedirect({
        authorizationParams: {
            screen_hint: label==="Register" && 'signup',}
    })}>
        <Text style={{ textDecoration: !isMobile &&  "underline" }} size={isMobile ? "xsmall" : "medium"}>
            {label}
        </Text>
    </Button>);
};

export default LoginButton;