import React, { Fragment, useContext } from "react";
import styled, { keyframes } from "styled-components";
import { Box, Button, Card, CardBody, CardFooter, Text, ResponsiveContext } from "grommet";
import AddPerkPoint from "./addPerkPoint";
import SeatAvailability from "./chatGptSeatsLeft";

const SubscriberCard = ({ card, edit, index, jointextColor, joinBGColor, cardClickHandler }) => {
  const size = useContext(ResponsiveContext);
  

  const getIcon = (currency) => {
    var curSymbol = currency.toUpperCase();
    if (currency === "USD") {
      curSymbol = "$";
    } else if (currency === "INR") {
      curSymbol = "₹";
    }
    return <Text size="xlarge">{curSymbol}</Text>;
  };

  const handleJoinButton = (index) => {
    cardClickHandler(index);
  };
  console.log("Nagendra card",card.cardType)
  const discountedAmount = (card.cardAmount - (card.cardAmount * card.cardDiscount / 100)).toFixed(2);

  const renderSeatStatus = () => {
    return (
        <Box>
                  <SeatAvailability seatsLeft={card.cardAvailableSeats} />
    {/* //   <SeatAvailability seatsLeft={8} />
    //   <SeatAvailability seatsLeft={28} />
    //   <SeatAvailability seatsLeft={0} /> */}
        </Box> 
    )
    if (card.cardAvailableSeats === 0) {
      return <SoldOutBadge>Sold Out</SoldOutBadge>;
    } else {
      return (
        <SeatsStatus>
          {card.cardAvailableSeats <= 30 && <HurryUpNotification>Hurry Up!</HurryUpNotification>}
          <SeatsText>{card.cardAvailableSeats} seats left</SeatsText>
        </SeatsStatus>
      );
    }
  };

  return (
    <StyledCard key={index} flex elevation="large" width="medium" size={size}>
      <CardBody height="xxsmall" align="start" pad="small">
      {card.cardType !== "Donation" && (
        <StatusContainer>
          {renderSeatStatus()}
        </StatusContainer>
      )}
        <Text size="2xl" style={{ fontWeight: "500" }} color="black" alignSelf="center" margin='0.75rem'>
          {card.cardTitle}
        </Text>
        {card.cardType !== "Donation" && (
        <Box direction="row" alignSelf="center" justify="baseline" style={{ color: "black" }}>
          {getIcon(card.cardCurrency)}
          {card.cardDiscountAvailable && card.cardDiscount > 0 && card.cardDiscountMembers > 0 ? (
            <>
              <Text size="xlarge" alignSelf="baseline" style={{ textDecoration: 'line-through', whiteSpace: 'pre-wrap', marginRight: '8px' }}>
                {" " + card.cardAmount}
              </Text>
              <DiscountedPrice>
                {` ${discountedAmount}`}
              </DiscountedPrice>
            </>
          ) : (
            <Text size="xlarge" alignSelf="baseline" style={{ whiteSpace: 'pre-wrap', marginRight: '8px' }}>
              {" " + card.cardAmount}
            </Text>
          )}
          <Text style={{ whiteSpace: 'pre-wrap' }} alignSelf="baseline">
            {" / " + card.cardPeriod}
          </Text>
        </Box>

        )}

        <Box>
          <ul>
            {card.cardPerkPoints.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
            {edit && <AddPerkPoint />}
          </ul>
        </Box>
      </CardBody>
      <CardFooter justify="center" align="center">
        <Button
          label={card.cardType === "Donation" ? "Donate Now" : "Join"}
          color={jointextColor}
          onClick={() => handleJoinButton(index)}
          style={{
            background: joinBGColor,
            borderRadius: '24px',
            width: '90%',
            letterSpacing: "2px",
          }}
        />
      </CardFooter>
    </StyledCard>
  );
};

// Keyframes for pulse animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;


const pulse2 = keyframes`
  0% {
    transform: scale(1);
    color: darkred;
  }
  50% {
    transform: scale(1.15);
    color: red;
  }
  100% {
    transform: scale(1);
    color: darkred;
  }
`;
// Styled Components

const StyledCard = styled(Card)`
  min-width: min-content;
  max-width: 700px;
  width: ${props => (props.size === 'xsmall' ? '300px' : '384px')};
  position: relative;
  padding: 16px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 8px;
  }
`;

const StatusContainer = styled.div`
  position: relative;
  margin-bottom: 24px; /* Ensure space for title */
  display: flex;
  justify-content: flex-end; /* Align badge to the right */
  align-items: flex-start; /* Align badge to the top */
`;

const DiscountedPrice = styled(Text)`
  font-size: 1.5em;
  font-weight: bold;
  color: red;
  white-space: pre-wrap;
  margin-right: 8px;
`;

const SeatsStatus = styled.div`
  background-color: #031635; /* Blue background for regular status */
  color: white;
  padding: 8px 12px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1em;
  animation: ${pulse} 3s infinite; /* Apply pulse animation */
`;

const HurryUpNotification = styled.div`
  background-color: #ff5722; /* Orange background for urgency */
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 0.9em;
  margin-right: 8px;
  animation: ${pulse} 3s infinite; /* Apply pulse animation */
`;

const SeatsText = styled.span`
  font-size: 1em;
`;

const SoldOutBadge = styled.div`
  background-color: #f44336; /* Red background for sold out */
  color: white;
  padding: 8px 12px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 1em;
  animation: ${pulse} 2s infinite; /* Apply pulse animation */
`;

export default SubscriberCard;
