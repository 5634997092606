import React from "react";

import { Nav, Button } from "grommet";
import { useNavigate } from "react-router-dom";

const DashBoardLabels = () => {

    const navigate = useNavigate();

    const dashBoardLink = "/dashboard";

    const goToDashboardPage = () => {
      navigate(dashBoardLink);
      // setdashboardActive(false);
    };

    const productLink = "/purchases";

    const product_click_handler = () => {
      navigate("/purchases");
    } 
    // const product_component = (
    //   <Button
    //     onClick={product_click_handler}
    //   >
    //     <Text size="xsmall">Products</Text>
    //   </Button>
    // );

    return (
        <Nav align="center" flex={false} direction="row" gap="xsmall">
        <Button
          active={window.location.pathname === dashBoardLink}
          label="Dashboard"
          reverse={false}
          onClick={goToDashboardPage}
        />
        <Button
          active={window.location.pathname === productLink}
          label="Products"
          reverse={false}
          onClick={product_click_handler}
        />

      </Nav>
    );
}

export default DashBoardLabels;
