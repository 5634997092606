import { Box, Grid, Grommet, Header, Page, Text } from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
import MembersCard from "../components/membersCard";
import GridStyledCards from "../components/GridStyledCards";
import { PageLoader } from "../components/pageLoader";
import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-react";

const Dashboard = () => {
  // const { getAccessTokenSilently } = useAuth0();
  const { isLoading } = useAuth0();

  // const [username, Setname] = React.useState('None')
  // let loginState = (isAuthenticated ?  "True" : "False" ); //Delete it after use

  // console.log(username);
  // return (
  //     <h1>{username}</h1>
    // );
    
    const items = [
      {
        name: "Communities",
        description: "Manage your communites",
        linkTo: "/communities",
        disabled: false,
    },
        {
            name: "Members",
            description: "Manage your members",
            linkTo: "/members",
            disabled : false
        },
        {
            name: "Analytics",
            description: "Manage Analytics",
          linkTo: "/analytics",
          disabled : false
        },
        {
            name: "Subscriptions",
            description: "Manage your placeholder",
          linkTo: "/subscriptions",
          disabled : true
        },
        {
          name: "Wallet",
          description: "Manage your Funds",
        linkTo: "/transactions",
        disabled : false
      },

    ]

    if(isLoading) return <PageLoader/>
  return (
    // <Page margin="medium">
    <Box flex>
                    <GridStyledCards items={items}/>
    </Box>


    // </Page>
  );
};

export default Dashboard;
