import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  ResponsiveContext,
  Image,
  Anchor,
  Carousel,
} from "grommet";
import { Magic, Ticket, Workshop, Money } from "grommet-icons";
import "../HomePage.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useNavigate } from "react-router-dom";

const HomePageOneTime = () => {
  const [titleIndex, setTitleIndex] = useState(0);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [textIndex, setTextIndex] = useState(0);
  const navigate = useNavigate();

  const topTitle = "Welcome to Your Payment Platform";
  const fancyTitles = [
    "Organize Events Seamlessly",
    "Collect Payments Effortlessly",
    "Manage Your Sales Smartly",
    "Host Workshops Professionally",
  ];
  const titles = ["All for FREE", "Join us TODAY."];
  const subTitleValues = [" Save ", " Earn "];
  const [subTitleIndex, setSubtitleIndex] = useState(0);

  const size = useContext(ResponsiveContext);
  const isMobile = size === "xsmall";

  useEffect(() => {
    const interval = setInterval(() => {
      setTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const getStarted = () => {
    loginWithRedirect({
        authorizationParams: {
            screen_hint: 'signup',}
    })
  }

  useEffect(() => {
    const randomNum = Math.floor(Math.random() * (5000 - 500 + 1)) + 2000;
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % fancyTitles.length);
    }, randomNum);



    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const randomNum = Math.floor(Math.random() * (10000 - 500 + 1)) + 2500;
    const interval2 = setInterval(() => {
      setSubtitleIndex((prevSubtitleIndex) => (prevSubtitleIndex + 1) % 2);
    }, randomNum);
    return () => clearInterval(interval2);
  }, []);

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const subtitleStyle = {
    margin: isMobile ? "2px" : "3px",
    color: "#f5f5f5b8",
    size: isMobile ? "medium" : "large",
  };

  const titleTextStyle = {
    size: isMobile ? "2xl" : "5xl",
    color: "white",
  };

  return (
    <Box fill>
      <Box align="center" justify="center" pad="large" background="#031635">
        <Text
          alignSelf="center"
          textAlign="center"
          color="#f5f5f5b8"
          size={isMobile ? "large" : "xxlarge"}
          margin="xsmall"
        >
          {topTitle}
        </Text>
        <Text
          key={textIndex}
          className="slide-up"
          textAlign="center"
          alignSelf="center"
          color="white"
          size={isMobile ? "xxlarge" : "6xl"}
          margin="xsmall"
          style={{ marginRight: "medium" }}
        >
          {fancyTitles[textIndex]}
        </Text>
        <Text alignSelf="center" textAlign="center" {...titleTextStyle}>
          {titles[0]}
        </Text>
        <Text alignSelf="center" textAlign="center" {...titleTextStyle}>
          {titles[1]}
        </Text>
        <Box
          alignSelf="center"
          direction="row"
          margin={{ top: isMobile ? "small" : "large" }}
        >
          <Text
            alignSelf="center"
            // className="move-down"
            key={subTitleIndex}
            textAlign="center"
            {...subtitleStyle}
          >
            {subTitleValues[subTitleIndex]}
          </Text>
          <Text alignSelf="center" textAlign="center" {...subtitleStyle}>
            More
          </Text>
          <Text
            alignSelf="center"
            // className="move-down"
            textAlign="center"
            {...subtitleStyle}
            key={(subTitleIndex + 1) % 2}
          >
            {subTitleValues[(subTitleIndex + 1) % 2]}
          </Text>
          <Text alignSelf="center" textAlign="center" {...subtitleStyle}>
            More
          </Text>
        </Box>

        <Heading level="2" color="light-1" margin={{ bottom: "medium" }} onClick={getStarted}>
          Get Started
        </Heading>
      </Box>

      <Box
        fill="horizontal"
        align="center"
        justify="center"
        pad="large"
        background="#ffffff08"
      >
          
        <Box
          height={isMobile ? "small" : "medium"}
          width="large"
          background="dark-1"
          round="small"
          overflow="hidden"
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/6-ZTlp3tzyQ?rel=0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>




      <Box
        fill="horizontal"
        align="center"
        justify="center"
        pad="large"
        background="#d6ecff"
      >
        <Heading level="2" color="#031635" margin={{ bottom: "medium" }}>
          How It Works
        </Heading>
        <Box
          direction={isMobile ? "column" : "row"}
          gap="medium"
          justify="center"
        >
          <StepCard
            title="1. Create Your Event"
            description="Start by setting up your event details in just 2 minutes."
          />
          <StepCard
            title="2. Share and Promote"
            description="Share your event link and start promoting to attract attendees."
          />
          <StepCard
            title="3. Collect Payments"
            description="Easily collect one-time payments from attendees and manage your funds."
          />
        </Box>
      </Box>

      <Box
        fill="horizontal"
        align="center"
        justify="center"
        pad={{ vertical: "large" }}
        background="#d6ecff"
      >
        {/* <Heading level="2" color="#031635" margin={{ bottom: 'medium' }}>Our Features</Heading> */}
        <Box direction="row" gap="large" justify="center" wrap>
          <FeatureCard
            icon={<Ticket color="#031635" />}
            title="Event Organization"
            description="Plan and manage your events with ease."
          />
          <FeatureCard
            icon={<Workshop color="#031635" />}
            title="Product Sales"
            description="Sell your products with a seamless payment process."
          />
          <FeatureCard
            icon={<Magic color="#031635" />}
            title="Service Payments"
            description="Collect payments for various services effortlessly."
          />
          {/* <FeatureCard icon={<Money />} title="Fundraising Campaigns" description="Raise funds for your cause efficiently." /> */}
        </Box>
      </Box>

            {/* Pricing Information */}
            <Box
        pad="large"
        align="center"
        justify="center"
        background="#031635"
        round="small"
        margin="medium"
        direction={isMobile ? "column" : "row-responsive"}
        gap={isMobile ? "xsmall" : "xlarge"}
      >

        <Box         align="center"
        justify="center">
        <Text size="xxlarge" weight="bold" color="light-1">
          Pricing
        </Text>
        </Box>
        <Box         align="center"
        justify="center" animation="pulse">

        
        <Text size={isMobile ? "small" : "medium"} color="light-1" >
          Start your 30-day free trial today!
        </Text>
        <Text size={isMobile ? "10px" : "medium"} color="skyblue">
          It's just 2% (including Payment Gateway charges).
        </Text>
        <Text size={isMobile ? "6px" : "small"} color="skyblue">
            Prices are inclusive of GST.
        </Text>
        </Box>
      </Box>

      <Box
        fill="horizontal"
        align="center"
        justify="center"
        // pad={{ vertical: "large" }}
        background="#ffffff08"
      >
        <Heading level="2" color="#031635" margin={{ bottom: "medium" }}>
          What our users say
        </Heading>
        <Box direction="row" gap="medium">
          <Carousel  play={2500} controls={isMobile ? "arrows" : false}
>
            <TestimonialCard
              text="This platform made it so easy to organize my event and collect payments!"
              author="Amit, Event Organizer"
              isMobile={isMobile}
            />
            <TestimonialCard
              text="Highly recommend for anyone looking to manage their sales efficiently."
              author="Pooja, Small Business Owner"
              isMobile={isMobile}
            />
            <TestimonialCard
              text="Seamless payment collection and great customer support!"
              author="Ravi, Freelance Consultant"
              isMobile={isMobile}
            />
            <TestimonialCard
              text= "The main reason I am using Membeed is because of the lowest cost combined with high security, along with integration with PhonePe."
              author="Ramakrishna Reddy, Content Creator"
              isMobile={isMobile}
            />
           
          </Carousel>
          {!isMobile && (
            <Carousel controls={false} play={2500}>

            <TestimonialCard
              text="Highly recommend for anyone looking to manage their sales efficiently."
              author="Pooja, Small Business Owner"
              isMobile={isMobile}
            />
            <TestimonialCard
              text="Seamless payment collection and great customer support!"
              author="Ravi, Freelance Consultant"
              isMobile={isMobile}
            />
            <TestimonialCard
              text= "The main reason I am using Membeed is because of the lowest cost combined with high security, along with integration with PhonePe."
              author="Ramakrishna Reddy, Content Creator"
              isMobile={isMobile}
            />
                        <TestimonialCard
              text="This platform made it so easy to organize my event and collect payments!"
              author="Amit, Event Organizer"
              isMobile={isMobile}
            />
            </Carousel>
          )}
          {!isMobile && (
            <Carousel controls={false} play={2500}>

            <TestimonialCard
              text="Seamless payment collection and great customer support!"
              author="Ravi, Freelance Consultant"
              isMobile={isMobile}
            />
            <TestimonialCard
              text= "The main reason I am using Membeed is because of the lowest cost combined with high security, along with integration with PhonePe."
              author="Ramakrishna Reddy, Content Creator"
              isMobile={isMobile}
            />
                        <TestimonialCard
              text="This platform made it so easy to organize my event and collect payments!"
              author="Amit, Event Organizer"
              isMobile={isMobile}
            />
            <TestimonialCard
              text="Highly recommend for anyone looking to manage their sales efficiently."
              author="Pooja, Small Business Owner"
              isMobile={isMobile}
            />
            </Carousel>
          )}
        </Box>
      </Box>

      <Box
        fill="horizontal"
        align="center"
        justify="center"
        pad="large"
        background="#031635"
      >
        <Button primary label="Sign Up Now" color="accent-1" onClick={getStarted}/>
      </Box>
    </Box>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <Box
    pad="medium"
    background="#d6ecff"
    round="small"
    align="center"
    gap="small"
    width="medium"
    animation="fadeIn"
  >
    <Box pad="small" background="#d6ecff" round="full" animation="pulse">
      {icon}
    </Box>
    <Heading level="3" margin="none" color="#031635">
      {title}
    </Heading>
    <Text textAlign="center" color="#031635">
      {description}
    </Text>
  </Box>
);

const TestimonialCard = ({ text, author, isMobile }) => (
  <Box
    pad="medium"
    background="light-1"
    round="small"
    align="center"
    gap="small"
    width="medium"
    animation="fadeIn"
    overflow="auto"
  >
    <Text textAlign="center" size={(text.length > 120 || isMobile) ? "small" : "large"} color="#031635">
      "{text}"
    </Text>
    <Text textAlign="center" size={isMobile ? "small" : "medium"}  color="#031635">
      - {author}
    </Text>
  </Box>
);

const StepCard = ({ title, description }) => (
  <Box
    pad="medium"
    background="#031635"
    round="small"
    align="center"
    gap="small"
    width="medium"
    animation="fadeIn"
  >
    <Heading level="2" margin="none" color="white">
      {title}
    </Heading>
    <Text textAlign="center" color="white">
      {description}
    </Text>
  </Box>
);

export default HomePageOneTime;
