import { Box, Button, Grommet, Header, ResponsiveContext, Text } from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment, useContext } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
import { Services } from "grommet-icons";
// import { useAuth0 } from "@auth0/auth0-react";

const NotFoundPage = ({message}) => {

    const size = useContext(ResponsiveContext);
    const isMobile = size === "xsmall";
    
    return (
        <Box gap="small" justify="center" alignSelf="Center" align="center" flex>

            <Text size={isMobile ? "xlarge" : "6xl"}> 
                {message || "Not Found"}
            </Text>

            <Box animation="jiggle">
            {/* <Box animation="pulse"> */}
                <Services size={isMobile ? "xlarge" : "40px"}/>
            {/* </Box> */}
            </Box>



        </Box>
    )
};

export default NotFoundPage;
