// PaginationTableExample.js
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Data,
  DataSearch,
  DataFilters,
  Toolbar,
  DataTable,
  Heading,
  Meter,
  Text,
  ResponsiveContext,
  DropButton,
  Button,
  Layer,
  Menu,
  Notification,
} from "grommet";
import { More, FormClose } from "grommet-icons";
import { useNavigate } from "react-router-dom";
import AlertContinue from "./alertContinue";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export const CommunityTable = ({ communityList, getUserCommsAPI}) => {

  const [commDetailsFromTable, setcommDetailsFromTable] = useState();
  const [showDetailsFromTable, setShowDetailsFromTable] = useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [selectedCommName, setSelectedCommName] = useState();
  const [selectedCommLink, setSelectedCommLink] = useState();
  const [commTableError, setCommTableError] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const displayDeletePopUp = (open) => {
    if (open === true) {
      setShowDeletePopUp(true);
      return;
    }
    setShowDeletePopUp(false);
  }

  const CloseViewDetailsHandler = () => {
    setShowDetailsFromTable(false);
  };


  const togglePauseCommunityRequest = async ({ status, commLink }) => {
    if ((status !== "enable" && status !== "disable") || !commLink) {
      return;
    }
    if (commLink.length > 1) {
      commLink = commLink.substring(1);
    }
    

    // Make a request here
    const toggleCommStatusURL =
      process.env.REACT_APP_BACKEND_URL + "/api/communities/" + commLink + "?status=" + status;

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: toggleCommStatusURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      }
    };

    // console.log("config to fetch ", config);

    // Show the order status and close the layer along with it in 5 seconds
    const closeTime = 4000;
    try {
      const response = await axios.request(config);
      if (response && response.status === 200) {
        setCommTableError(status + "d Successfully");
        // load silently
        getUserCommsAPI(true);
      } else {
        throw new Error("No response or failed");
      }
    } catch (error) {
      setCommTableError("Try after sometime");
      // console.log("error in withdrawal request", error);
    } finally {
      setTimeout(() => {
        setCommTableError(false);
      }, closeTime);
    }
    return;
  }

  const deleteCommunityRequest = async ({ commLink }) => {
    commLink = selectedCommLink;
    if (!commLink) {
      return;
    }
    if (commLink.length > 1) {
      commLink = commLink.substring(1);
    }

    // Make a request here
    const deleteCommURL =
      process.env.REACT_APP_BACKEND_URL + "/api/communities/" + commLink;

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: deleteCommURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      }
    };
    // console.log("config to fetch ", config);

    // Show the order status and close the layer along with it in 5 seconds
    const closeTime = 4000;
    try {
      const response = await axios.request(config);
      if (response && response.status === 200) {
        setCommTableError("Deleted Successfully");
        getUserCommsAPI(true);
      } else {
        throw new Error("No response or failed");
      }
    } catch (error) {
      setCommTableError("Try after sometime");
      // console.log("error in withdrawal request", error);
    } finally {
      setTimeout(() => {
        setCommTableError(false);
      }, closeTime);
    }
    return;
  }

  const Navigate = useNavigate();

  const handleClickRow = (obj) => {
    Navigate("/edit" + obj.nameLink)
    // setcommDetailsFromTable(obj);
    // setShowDetailsFromTable(true);  abhi_needToDo
  };

  const size = useContext(ResponsiveContext);
  // console.log("Got community list @ commTable : ", communityList);
  if (!communityList || communityList.length === 0) return <></>;

  const cellHoverStyle = {
    backgroundColor: "transparent",
    color: "rgb(68,68,68)",
    padding: "12px 0px",
    alignContent: "center",
  };

  const columns = [
    // {
    //   property: "_id",
    //   header: "Subscription Key",
    //   render: (datum) => <Text weight="bold">{datum._id}</Text>,
    //   primary: true,
    // },
    {
      property: "name",
      header: "Community Name",
      plain: true,
      //   datum.basicDetails && datum.basicDetails.commTitleName &&
      render: (datum) => {
        // console.log("Datum in Community Name column:", datum); // Log datum to the console

        return (
          <Button
            fill
            style={cellHoverStyle}
            onMouseEnter={() => { }}
            onClick={(e) => {
              e.preventDefault();
              handleClickRow(datum);
            }}
          >
            <Text>{datum?.basicDetails && datum?.basicDetails?.commTitleName}</Text>
          </Button>
        );
      }
    },

    {
      property: "orgId",
      header: "Link",
      plain: true,
      render: (datum) =>
        datum &&
        datum.nameLink && (
          <Button
            fill
            alignSelf="center"
            style={cellHoverStyle}
            onMouseEnter={() => { }}
            onClick={(e) => {
              e.preventDefault();
              handleClickRow(datum);
            }}
          >
            <Text > membeed.com{datum?.nameLink}</Text>
          </Button>
        ),
    },

    {
      property: "description",
      header: "Members",
      plain: true,
      align: "center",
      // units: "TB",
      render: (datum) =>
        datum &&
        datum.subscriberUsers && (
          <Button
            fill
            style={cellHoverStyle}
            onMouseEnter={() => { }}
            onClick={(e) => {
              e.preventDefault();
              handleClickRow(datum);
            }}
          >
            <Text truncate>{datum?.subscriberUsers?.length}</Text>
          </Button>
        ),
    },
    // pointer-events:none;

    {
      property: "status",
      header: "Status",
      align: "center",
      plain: true,
      render: (datum) =>
        datum && (
          <Button
            fill
            style={cellHoverStyle}
            onMouseEnter={() => { }}
            onClick={(e) => {
              e.preventDefault();
              handleClickRow(datum);
            }}
          >
            <Text>{(!datum?.status || datum?.status === "enable") ? "Active" : "Paused"}</Text>
          </Button>
        ),
    },

    //   More Button
    {
      property: "savings",
      primary: true,
      plain: true,
      // style: { PointerEvent: "none" },
      align: "center",
      render: (datum) => (
        <Menu
          icon={<More />}
          dropAlign={{ right: "right", top: "bottom" }}
          onClick={(e) => {
            e.preventDefault();
          }}
          fill
          style={{ maxWidth: "40px" }}
          items={[
            {
              label: datum.status === "disable" ? "Enable" : "Pause",
              justify: "center",
              disabled: false,
              onClick: async () => {
                if (datum?.nameLink) {
                  setSelectedCommLink(datum?.nameLink);
                  await togglePauseCommunityRequest({ commLink: datum?.nameLink, status: datum.status === "disable" ? "enable" : "disable" });  //enable if it is active or else disable
                }
              },
            },
            {
              label: "Delete",
              justify: "center",
              disabled: false,
              onClick: () => {
                if (datum?.nameLink && datum?.basicDetails?.commTitleName) {
                  setSelectedCommName(datum?.basicDetails?.commTitleName);
                  setSelectedCommLink(datum?.nameLink);
                }
                displayDeletePopUp(true);  //enable if it is active or else disable
              },
            },
          ]}
        ></Menu>
      ),
      sortable: false,
    },
  ];

  //   console.log(props);


  //   useEffect(() => {
  //     console.log(props.data);
  //   }, [props.data]);

  const comlist = [
    {
      _id: "667539a018e9c4142eef0890",
      nameLink: "/telegram",
      basicDetails: {
        commTitleName: "Sample Title",
        commTitleColor: "#FFFFFF",
        commTitleBGColor: "#000000",
        commProfilePic: "http://example.com/pic.jpg",
      },
      plans: [
        {
          planName: "Basic Plan",
          currency: "USD",
          price: 10,
          period: "Monthly",
          points: ["Feature 1", "Feature 2"],
        },
      ],
      checkoutDetails: {
        preCheckoutUserInputs: {
          name: "User Name",
          phNumber: "1234567890",
          socialIds: {
            facebook: "fb_user",
            twitter: "twitter_user",
          },
        },
        postCheckoutMsg: "Thank you for your purchase!",
        redirect: {
          isRedirect: true,
          redirectURI: "http://example.com/redirect",
        },
      },
      subscriberUsers: [
        {
          name: "Subscriber 1",
          number: "0987654321",
          socialIds: {
            linkedin: "linkedin_user",
          },
        },
      ],
    },
  ];



  

  // console.log("from communityTable : ", communityList);
  // console.log("defined communityTable : ", comlist);
  return (
    <Box>
      <Box>
        <DataTable
          aria-describedby="storage-pools-heading"
          data={communityList}
          columns={columns}
          onClickRow={(e) => {
            e.preventDefault();
            // handleClickRow(datum);
          }}
          paginate={{
            border: "top",
            direction: "row",
            fill: "horizontal",
            flex: false,
            justify: !["xsmall", "small"].includes(size) ? "end" : "center",
            pad: { top: "xsmall" },
          }}
          // Known issue - step(3-5) is creating extra rows to be added, after coming back from page 2 to 1
          // abhi_debug- - https://github.com/grommet/grommet/issues/3987
          // Solved : if columns are not having proper property as in props.data - will cause above issue
          step={10}
        // sortable
        />
        {/* </Data> */}
      </Box>
      {showDeletePopUp && <AlertContinue
        opMessage="community : "
        // optional_params={commSelected}
        optional_params={selectedCommName}
        messageType='Critical'
        callback={deleteCommunityRequest}
        onClose={displayDeletePopUp}
      />}
        {commTableError && <Notification
              toast
              status="normal"
              title={commTableError}
              onClose={() => setCommTableError()}
            />}
    </Box>
  );
};
