import {
  Box,
  Button,
  Grommet,
  Header,
  Menu,
  ResponsiveContext,
  Text,
} from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment, useContext } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
import ProfileDrop from "./profileDrop";
import LoginButton from "./loginButton";
import { Apps, Menu as MenuIcon } from "grommet-icons";
import { Navigate, useNavigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";

const login_component = <LoginButton label="Login" />;
const register_component = <LoginButton label="Register" />;

const LoginLabel = () => {
  // const { getAccessTokenSilently } = useAuth0();
  // const { isAuthenticated } = useAuth0();

  const size = useContext(ResponsiveContext); // screen size
  const isMobile = size === "xsmall";
  const navigate = useNavigate();

  const product_click_handler = () => {
    navigate("/purchases");
  } 
  const product_component = (
    <Button
      onClick={product_click_handler}
    >
      <Text size="xsmall">Products</Text>
    </Button>
  );

  // const [username, Setname] = React.useState('None')
  // let loginState = (isAuthenticated ?  "True" : "False" ); //Delete it after use

  // console.log(username);
  // return (
  //     <h1>{username}</h1>
  // );


  if (isMobile) {
    return (
      <Menu
        icon={<MenuIcon />}
        // label="Menu"
        items={[
          { label: login_component },
          { label: register_component },
          {
            label: product_component,
          },
        ]}
        // dropAlign={{
        //     align: { left: 'right' },
        // }}
      />
    );
  }

  return (
    <Box direction="row" gap="small">
      <LoginButton label="login" />
      <LoginButton label="register" />
    </Box>
  );
};

export default LoginLabel;
