import { Box, Button, Grommet, Header, Text } from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import axios from "axios";
import { Checkmark, Close } from "grommet-icons";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashBoardLabels from "../components/dashboardLabels";
import Title from "../components/title";
import NotFoundPage from "./notFound";
import { PageLoader } from "./pageLoader";
// import { useAuth0 } from "@auth0/auth0-react";

const validateCommunityData = (data) => {
  const errors = [];
  if (
    !data.nameLink ||
    typeof data.nameLink !== "string" ||
    !data.nameLink.match(/^\/[a-zA-Z0-9_]*$/)
  ) {
    errors.push("nameLink is invalid.");
    return "Not a valid URL";
  }
  if (!data.basicDetails) {
    errors.push("basicDetails is required.");
    return "Not a valid title";
  }
  if (data.basicDetails) {
    if (!data.basicDetails.commTitleName) {
      errors.push("TitleName is required.");
      return "Title Name is required.";
    }
    if (!data.basicDetails.commTitleColor) {
      errors.push("commTitleColor is required.");
      return "Title Color is required.";
    }
    if (!data.basicDetails.commTitleBGColor) {
      errors.push("commTitleBGColor is required.");
      return "Title Background is required.";
    }
  }
  if (
    !data.plans ||
    !Array.isArray(data.plans) ||
    data.plans.length === 0 ||
    data.plans.length > 3
  ) {
    errors.push("Invalid plans");
    return "Invalid plans";
  }
  if (data.plans && Array.isArray(data.plans)) {
    for (let i = 0; i < data.plans.length; i++) {
      const plan = data.plans[i];
      if (!plan.planName || plan.planName === "") {
        return `Plan name is required for plan ${i + 1}.`;
      }
      if (!plan.currency || plan.currency === "") {
        return `Currency is required for plan ${i + 1}.`;
      }
      if (plan.price === null || plan.price === "") {
        return `Price is required for plan ${i + 1}.`;
      }
      if (!plan.period || plan.period === "") {
        return `Period is required for plan ${i + 1}.`;
      }
      if (
        !plan.points ||
        !Array.isArray(plan.points) ||
        plan.points.length === 0 ||
        plan.points.length > 5
      ) {
        return `Points are not valid for plan ${i + 1}.`;
      }
    }
  } else {
    errors.push("Not a valid plans array");
    return "Invalid Plans";
  }
  if (data.checkoutDetails) {
    if (!data.checkoutDetails.preCheckoutUserInputs) {
      errors.push("Pre checkout User Inputs is required.");
      return false;
    } else {
      if (
        !data.checkoutDetails.preCheckoutUserInputs.basicDetails ||
        !Array.isArray(
          data.checkoutDetails.preCheckoutUserInputs.basicDetails ||
          data.checkoutDetails.preCheckoutUserInputs.basicDetails.length === 0
        )
      ) {
        errors.push("invalid basicDetails");
        return "Select either Name or Number";
      }
      if (
        !data.checkoutDetails.preCheckoutUserInputs.socialIds ||
        !Array.isArray(data.checkoutDetails.preCheckoutUserInputs.socialIds)
      ) {
        errors.push("invalid socialIds");
        return "Invalid Social IDs";
      }
      if (
        (data.checkoutDetails.preCheckoutUserInputs.socialIds.includes(
          "Other"
        ) &&
          !data.checkoutDetails.preCheckoutUserInputs.otherId) ||
        typeof data.checkoutDetails.preCheckoutUserInputs.otherId !== "string"
      ) {
        errors.push("invalid otherId");
        return "Invalid Social ID (Other)";
      }
    }
    if (!data.checkoutDetails.postCheckoutDetails) {
      errors.push("postCheckoutMsg is required in checkoutDetails.");
      return false;
    } else {
      const { isRedirect, redirectURI, checkoutMsg } =
        data.checkoutDetails.postCheckoutDetails;
      if (typeof isRedirect !== "boolean") {
        errors.push("Invalid type for 'isRedirect'. Expected boolean.");
        return "Invalid type for 'Redirect to a Link'";
      }

      if (typeof redirectURI !== "string") {
        errors.push("Invalid type for 'redirectURI'. Expected string.");
        return "Invalid type for 'redirect URL'.";
      }

      if (typeof checkoutMsg !== "string") {
        errors.push("Invalid type for 'checkoutMsg'. Expected string.");
        return "Invalid type for 'checkoutMsg'.";
      }
    }
  } else {
    errors.push("No checkoutDetails");
    return "No checkoutDetails";
  }
  return null;
};

const PostPaymentPage = ({ route, message }) => {

  // console.log("routParams : ", route.params );
  const location = useLocation();
  const { commName, userObjectID, merchantTransactionId, freeUser } = location.state || {};
    // console.log("merchantTransactionId : ", merchantTransactionId );
  // console.log("userObjectID : ", userObjectID );
  // console.log("commName : ", commName );

  const [isPageLoading, setIsPageLoading] = useState(false); // for fetch community api
  const [paymentStatus, setPaymentStatus] = useState("pending");  // payment status from PG 
  const [pageDetails, setPageDetails] = useState();  //to show redirect message and redirect part
  const [time, setTime] = useState(); // to show you will be redirected in 5 seconds
  const redirectionTime =  5; // in seconds


  const checkPaymentStatus = async () => {
    // Check for valid details when user is not returned after zero free joining
    if(freeUser) {
      setPaymentStatus("success");
      await getPublicCommunityAPI();
      return "success";
    }
    if (!freeUser && (!merchantTransactionId || !userObjectID || !commName)) {
      console.error("Need Transaction ID! ");
      setPaymentStatus("not_found");
      return "Incorrect details";
    }


    //once payment sttus is set, nobody can change it
    if(paymentStatus != "pending") return;

    const checkStatusURL =
      process.env.REACT_APP_BACKEND_URL +
      process.env.REACT_APP_PHONEPE_URL +
      "/status/" +
      merchantTransactionId + "/" + userObjectID + "/" + commName;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: checkStatusURL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      if (response) {
        if (response.status === 200) {
          const responseData = response.data;
          // console.log(responseData);
          if (responseData.message === "PAYMENT_SUCCESS") {
            setPaymentStatus("success");
            await getPublicCommunityAPI();
            return "success";
          }
          else if (responseData.message === "PAYMENT_ERROR") {
            setPaymentStatus("failed");
            return "failed";
          }
          else if (responseData.message === "PAYMENT_PENDING") {
            setTimeout(async() => {await checkPaymentStatus(merchantTransactionId) }, 1000);
            setPaymentStatus("pending");
            return "pending";
          }
          else {  /// INVALID_COMMUNITY_OR_USER or SOME_ERROR
              setPaymentStatus("not_found");
              return "not_found"
          }
        }
      } else {
        throw new Error("No Response ERROR");
      }
    } catch (error) {
      // console.log(error);
      setPaymentStatus("server_error");
      return "server_error";
    }
  };


  const getPublicCommunityAPI = async () => {
    if(!commName) return;
    setIsPageLoading(true);
    const queryObject = {
      query: "details",
    };

    const queryString = new URLSearchParams(queryObject).toString();

    const commURL =
      process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_COMM_SUBURL;

    const fullURL = commURL + "/" + commName + "?" + queryString;
    // console.log("full url : ", fullURL);

    // const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fullURL,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      var result;
      if (response?.status === 200 && response?.data) {
          const response_json = response.data;
          const anyErrors = validateCommunityData(response_json);
          if (!anyErrors) {
            // valid comm details has come
            setPageDetails(response_json);
            if(!time) {
              // console.log("SETTING TIME TO 5 SECONDS");
              setTime(redirectionTime);
          }
          }
          else{
            throw new Error("Fetched invalid Community");
          }
        }
      else {
        throw new Error("Invalid Response");
      }
    } catch (error) {
      // console.log(error);
    }
    setIsPageLoading(false);
  };


  useEffect(() => {
    const checkPaymentStatusOnlyOnce = async() => {
      await checkPaymentStatus();
    }
    if(paymentStatus === "pending"){
      checkPaymentStatusOnlyOnce();
    }
  }, []);

  useEffect(() => {
    if (time === null) return;

    const timer = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [time]);



  if(paymentStatus === "pending" || isPageLoading) return <PageLoader/>;
  else if(paymentStatus === "not_found") return <NotFoundPage message="Page not found"/>;
  else if(paymentStatus === "server_error") return <NotFoundPage message="Server Error"/>;
  else if(paymentStatus === "success"){
    if(
      pageDetails?.checkoutDetails?.postCheckoutDetails?.isRedirect && pageDetails?.checkoutDetails?.postCheckoutDetails?.redirectURI
    ){
      setTimeout( () => {
        // window.alert("Will be redirected : ", pageDetails?.checkoutDetails?.postCheckoutDetails?.redirectURI);
        window.location.href = pageDetails?.checkoutDetails?.postCheckoutDetails?.redirectURI
        // window.location.href = "https://mail.google.com/mail/u/0/#inbox";
      }, redirectionTime * 1000);
    }
  return (
    <Box flex  gap="small" justify="center" alignSelf="Center" align="center">
        <Box direction="row"> 
        <Checkmark color="green" size="large"/>
        <Text size="large" weight="bold" margin={{horizontal:"xsmall"}}>YOUR PAYMENT IS RECORDED</Text>
        </Box>

        {pageDetails && pageDetails?.checkoutDetails?.postCheckoutDetails?.checkoutMsg}

        {pageDetails?.checkoutDetails?.postCheckoutDetails?.isRedirect ? time !== null ? (
        <Text>You will be redirected in {time} seconds</Text>
      ) : (
        <Text>Loading...</Text>
      ) : <></>}
    </Box>
  );}
  else {  
  return (
    <Box flex direction="row" gap="small" justify="center" alignSelf="Center" align="center">
        <Close color="red"/>
        <Text weight="bold">YOUR PAYMENT IS RECORDED</Text>
    </Box>
  );
}
};

export default PostPaymentPage;
