// TermsAndConditions.js
import React from 'react';
import { Grommet, Box, Heading, Text } from 'grommet';
import { grommet } from 'grommet/themes';

const termsText = `
Effective Date: July 07, 2024
Introduction
Welcome to membeed.com (the "Website"). This document outlines the terms and conditions
("Terms") governing your use of the Website and the services offered by Membeed (the
"Company"). By accessing or using the Website, you agree to be bound by these Terms.
1. User Accounts
1.1 Users can create accounts on the Website. You are responsible for maintaining the
confidentiality of your account credentials and for all activities that occur under your account.
1.2 You agree to provide truthful and accurate information when creating your account.
1.3 You must be at least 18 years old to create an account and use the Website.
2. User Content
2.1 Users can create and upload content, including text and images.
2.2 You retain ownership of the content you upload to the Website. However, by uploading
content, you grant Membeed a non-exclusive, royalty-free license to use, reproduce, modify,
publish, and distribute your content on the Website and its related services.
2.3 You are solely responsible for the content you upload and ensure it does not violate any
third-party rights or intellectual property laws.
3. Subscriptions and Free Trials
3.1 Membeed offers subscription plans that provide access to certain features and functionalities
of the Website.
3.2 Subscription fees are clearly displayed before purchase.
3.3 Membeed may offer free trials for certain subscription plans. These trials are subject to the
terms specified at the time of sign-up.
3.4 You are responsible for timely payment of your subscription fees once the free trial period
ends, unless you cancel the subscription before the trial period ends.
3.5 Membeed reserves the right to modify or discontinue subscription plans and free trials at any
time with prior notice.
4. Online Services and Payments
4.1 Membeed sells online services through the Website. The details and pricing of these services
are provided at the time of purchase.
4.2 Payments for online services can be made using the payment methods specified on the
Website.
4.3 All payments are subject to validation checks and authorization by your payment provider. If
we do not receive the required authorization, we will not be liable for any delay or non-delivery
of services.
4.4 Refunds and cancellations for online services are governed by our Refund Policy, which is
available on the Website.
5. Proprietary Rights
5.1 The Website content, including logos, designs, trademarks, and other intellectual property, is
the exclusive property of Membeed. You may not use any of this content without written
permission.
6. User Feedback
6.1 You may provide feedback and suggestions to Membeed. By submitting feedback, you grant
Membeed the right to use this feedback without any compensation or attribution.
7. Promotions, Contests, and Sweepstakes
7.1 Membeed may offer promotions, contests, and sweepstakes on the Website. Each promotion,
contest, or sweepstake will have its specific terms and conditions.
8. Termination
8.1 Membeed reserves the right to suspend or terminate your account at any time for violating
these Terms or for any other reason deemed necessary.
9. Limitation of Liability
9.1 To the fullest extent permitted by law, Membeed shall not be liable for any indirect,
incidental, special, consequential, or punitive damages, or any loss of profits or revenues,
whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible
losses, resulting from (i) your use or inability to use the Website; (ii) any unauthorized access to
or use of our servers and/or any personal information stored therein.
10. Governing Law and Dispute Resolution
10.1 These Terms shall be governed by and construed in accordance with the laws of India.
10.2 Any dispute arising out of or relating to these Terms shall be subject to the exclusive
jurisdiction of the courts located in Bengaluru, Karnataka, India.
11. Changes to Terms
11.1 We reserve the right to modify or replace these Terms at any time. We will provide notice
of any changes by posting the new Terms on the Website. Your continued use of the Website
after such changes constitutes your acceptance of the new Terms.
12. Contact Us
12.1 If you have any questions about these Terms and Conditions, please contact us:
• By email: support@membeed.com
• By visiting this page on our website: https://membeed.com/t_n_c
`;


const refundText = `

Effective Date: July 07, 2024
1. Subscription Fees
1.1 Subscription fees for Membeed's services are non-refundable, except as expressly provided
in this Refund Policy or as required by applicable law.
1.2 If you cancel your subscription before the end of a billing cycle, you will not receive a refund
for any unused portion of the subscription period.
2. Free Trials
2.1 If you cancel your subscription during a free trial period, you will not be charged. No refunds
will be provided for free trial cancellations.
3. Online Services
3.1 Payments for online services provided by Membeed are non-refundable once the service has
been delivered or accessed, except as required by applicable law.
4. Refund Requests
4.1 To request a refund, you must contact Membeed's customer support team at
support@membeed.com within 3 hours of the original purchase or subscription renewal date.
4.2 Refund requests must include the following information:
• Your full name
• Date of purchase or subscription renewal
• Reason for the refund request
4.3 Membeed reserves the right to decline refund requests if we believe they are made in bad
faith or if we determine that the request does not meet our refund criteria.
5. Processing of Refunds
5.1 If your refund request is approved, refunds will be issued using the original payment method
used for the purchase or subscription.
5.2 Refunds may take 7 working days to process, depending on your payment provider.
6. Changes to Refund Policy
6.1 Membeed reserves the right to modify or update this Refund Policy at any time. Changes will
be effective immediately upon posting on the Website.
7. Contact Us
7.1 If you have any questions about this Refund Policy, please contact us:
• By email: support@membeed.com
• By visiting this page on our website: https://membeed.com/r_p_y
`

const privacyText=`

Effective Date: July 07, 2024
Membeed ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy
explains how we collect, use, disclose, and safeguard your information when you visit our
website https://membeed.com/ (the "Website") and use our services.
1. Information We Collect
1.1 Personal Information: When you create an account or interact with our services, we may
collect personal information such as your name, email address, and billing information.
1.2 Usage Data: We may collect information about your interactions with the Website, including
IP addresses, browser types, pages visited, and timestamps.
1.3 Cookies: We may use cookies and similar tracking technologies to enhance your user
experience and for analytics purposes. You can manage your cookie preferences through your
browser settings.
2. Use of Information
2.1 We use the information we collect to:
• Provide, operate, and maintain the Website and our services.
• Process your transactions and manage your subscriptions.
• Improve and personalize your experience on the Website.
• Communicate with you, including responding to your inquiries and providing customer
support.
• Send you promotional emails and updates about Membeed, which you can opt out of at
any time.
3. Disclosure of Information
3.1 We may disclose your information:
• To our service providers who assist us in operating the Website and providing our
services.
• When required by law or to protect our rights and safety, or the rights and safety of
others.
• In connection with a merger, acquisition, or sale of all or a portion of our assets.
4. Security of Information
4.1 We implement reasonable security measures to protect your information from unauthorized
access, alteration, disclosure, or destruction. However, no method of transmission over the
internet or electronic storage is completely secure, and we cannot guarantee absolute security.
5. Your Choices
5.1 You can update your account information and communication preferences by logging into
your account settings.
5.2 You can opt out of receiving promotional emails from us by following the instructions in
those emails or by contacting us using the information provided below.
6. Children's Privacy
6.1 Our services are not directed to individuals under the age of 18. We do not knowingly collect
personal information from children under 18. If we learn that we have collected personal
information from a child under 18, we will take steps to delete that information.
7. Changes to This Privacy Policy
7.1 We may update this Privacy Policy from time to time. We will notify you of any changes by
posting the new Privacy Policy on this page and updating the "Effective Date" above.
8. Contact Us
8.1 If you have any questions about this Privacy Policy, please contact us:
• By email: support@membeed.com
• By visiting this page on our website: https://membeed.com/t_n_c
`

const termsHeader = "Terms and Conditions";
const privacyHeader = "Privacy Policy";
const refundHeader = "Refund Policy";

const TermsAndConditions = ({ messageCode }) => {
  let displayHeader = privacyHeader;
  let displayText = privacyText;

  if (messageCode === "tnc") {
    displayHeader = termsHeader;
    displayText = termsText;
  } else if (messageCode === "refund") {
    displayHeader = refundHeader;
    displayText = refundText;
  }

  return (
    <Grommet theme={grommet}>
      <Box flex pad="large" align="center" background="light-2" height={{ min: '100vh' }}>
        <Box width="large" background="white" pad="large" elevation="small" round="small">
          <Heading level="2" margin={{ bottom: 'medium' }}>
            {displayHeader}
          </Heading>
          <Box height="medium" overflow="auto" pad={{ right: 'small' }}>
            {displayText.split('\n').map((text, index) => (
              <Text key={index} margin={{ bottom: 'small' }}>
                {text}
              </Text>
            ))}
          </Box>
        </Box>
      </Box>
    </Grommet>
  );
};

export default TermsAndConditions;