import { Box, Button, Grommet, Header, Text } from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
// import { useAuth0 } from "@auth0/auth0-react";

const NothingToShow = ({message}) => {

    return (
        <Box margin="large" pad="large" direction="row" gap="small" align="center" justify="center" alignSelf="center">

            <Text size="xxlarge"> 
                {message || "Not Found"}
            </Text>


        </Box>
    )
};

export default NothingToShow;
