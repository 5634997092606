import {Box, Text} from "grommet";
import {CircleAlert} from "grommet-icons";
import React from "react";

// a custom error box for forms
export default function FormFieldError({error}) {
    // Made for full screen
    // For half screen -6px, full screen -12px - marginTop
    return (
        <Box
            style={{
                display: "flex",
                boxSizing: "border-box",
                maxWidth: "100%",
                minWidth: "0px",
                minHeight: "0px",
                flexDirection: "row",

                // marginTop as we are not able to insert this error inside formfield
                marginTop: "-6px",
            }}
        >
            <CircleAlert
                style={{
                    display: "inline-block",
                    flex: "0 0 auto",
                    width: "12px",
                    height: "12px",
                    fill: "rgb(68, 68, 68)",
                    stroke: "rgb(68, 68, 68)",
                    marginTop: "4px",
                }}
                color="red"
            />
            <Box
                flex
                style={{
                    maxWidth: "6px",
                }}
            ></Box>
            <Text
                style={{
                    margin: "0px 0px 6px",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "red",
                }}
            >
                {error}
            </Text>
        </Box>
    );
};
