import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Page,
  PageHeader,
  Anchor,
  Select,
  Text,
  Button,
  ResponsiveContext,
  Layer,
} from "grommet";
import { hpe } from "grommet-theme-hpe";
import { useSpring, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { CurrencyRupee, Group } from "@mui/icons-material";
import NothingToShow from "../components/nothingToShow";
import WithdrawFunds from "../components/withdrawFunds";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "../components/pageLoader";
import axios from "axios";
import TransactionHistory from "../components/TransactionHistoryLayer";

const Wallet = () => {
  const size = useContext(ResponsiveContext);
  const isMobile = size === "xsmall";
  const { getAccessTokenSilently } = useAuth0();
  const [transactionsFetched, setTransactionsFetched] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showWithdrawalLayer, setShowWithdrawalLayer] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [membersData, setMembersData] = useState({
    totalMembers: 0,
    activeMembers: 0,
  });
  const [amountData, setAmountsData] = useState({
    totalAmount: 0,
    withDrawnAmount: 0,
    availableAmount: 0,
  });

  const withdrawHandler = () => {
    setShowWithdrawalLayer(true);
  };

  const navigate = useNavigate();
  const anchorClickHandler = () => {
    navigate(-1);
  };

  const mobileTitleTextStyle = {
    direction: size === "xsmall" ? "column" : "row",
  };

  const fetchWithdrawalTransactions = async () => {
    setIsPageLoading(true);
    // console.log("Need to fetch ");

    let getAmountsURL =
      process.env.REACT_APP_BACKEND_URL + "/api/admin/transactions";

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: getAmountsURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    };

    // console.log("config to fetch ", config);

    try {
      const response = await axios.request(config);
      if (response) {
        if (response.status === 200) {
          const responseData = response.data;
          setTransactionsFetched(responseData);
        }
      } else {
        throw new Error("No Response ERROR");
      }
    } catch (error) {
      // console.log(error);
      setTransactionsFetched();
      return "server_error";
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawalTransactions();
  }, []);

  const contentTitle = "Wallet";
  const contentSubTitle = "Manage your payments";

  return (
    <Page height="large">
      <PageHeader
        title={contentTitle}
        subtitle={contentSubTitle}
        pad="medium"
        parent={<Anchor label="Back" onClick={anchorClickHandler} />}
        actions={
          <Box gap="small">
            <Button
              onClick={withdrawHandler}
              busy={isButtonLoading}
              size={isMobile ? "xsmall" : "medium"}
              pad={{ horizontal: isMobile ? "none" : "small" }}
              secondary
              label="Withdraw"
            />
          </Box>
        }
      />
      {showWithdrawalLayer && (
        <WithdrawFunds
          showLayer={showWithdrawalLayer}
          setShowLayer={setShowWithdrawalLayer}
        />
      )}
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <TransactionHistory transactions={transactionsFetched} />
      )}
    </Page>
  );
};

export default Wallet;
