// Diverting point after landing into the page based on their addresses
// Diverts - user to empty Home if not logged in
// else to dashboard

import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard";
import DisplayPage from "./emptyHome";
import { Box, Grommet, Page, ResponsiveContext } from "grommet";

import { hpe } from "grommet-theme-hpe";

import Members from "./members";
import Analytics from "./analytics";
import Subscriptions from "./subscriptions";
import NavBarPage from "./navBarPage";
import CommunityPage from "./community";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from "../components/authenticationGuard";
import { PageLoader } from "../components/pageLoader";
import NotFoundPage from "../components/notFound";
import CommunitiesPage from "./communities";
import CommunityEditPage from "./editCommunity";
import { deepMerge } from "grommet/utils";
import { Add, FormCheckmark } from "grommet-icons";
import DisplayToken from "./displayToken";
import PostPaymentPage from "../components/paymentPaid";
import TermsAndConditions from "./tnc";
import Footer from './footer';
import Wallet from "./wallet";
import HomePageOneTime from "./emptyHomeOneTime";
import SearchComponent from "./productsPageForYamini";
import AuthErrorDisplayPage from "./authErrorDisplayPage";
import DiscountBanner from '../components/discountBanner';

const customTheme = deepMerge(hpe, {
  global: {
    colors: {
      custom: "#5793f4",
      brand: "#031635", // Set your desired brand color here
      focus: "#5793f4",
      background: "#FFFFFF",
      text: "#000000",
      hover: "#5793f4",
      'selected-background': "#5793f4",
      'selected-text': "#031635",
    },
    hover: {
      color: "#5793f4",
    },
  },
  checkBox: {
    check: {
      radius: '4px',
      extend: ({ theme, checked, indeterminate }) => `
      background-color: ${
        checked || indeterminate
          ? "#031635"
          : theme.global.colors.background[theme.dark ? 'dark' : 'light']
      };
      ${(checked || indeterminate) && 'border: none;'}
        `,
    }
  },
  select: {
    // Override the selected color for the Select component
    container: {
      color: "#5793f4",
    },
    icons: {
      color: "#031635",
    },
    control: {
      extend: `color: 'blue'`,
    },
  },
  icon: {
    background: "#031635",
    color: "#031635",
    extend: `fill: #031635;`,
    // checked : <FormCheckmark color='#031635'/>
  },
});

const Home = () => {
  var { isAuthenticated, user, isLoading } = useAuth0();
  const size = useContext(ResponsiveContext);

  // console.log(
  //   "In the home.js : isAuthenticated and isLoading",
  //   isAuthenticated,
  //   isLoading
  // );
  //For Developing
  // isAuthenticated = false;

  const allowed_paths = [
    "/",
    "/dashboard",
    "/members",
    "/subscriptions",
    "/transactions",
    "/communities",
    "/analytics",
    "/profile",
    "/purchases"
    // abhi_check if_any_urls_to_add
  ]


  const curPath = window.location.pathname;

  var display = allowed_paths.includes(curPath) || curPath.startsWith("/edit/");

  // useEffect(() => { }, [isAuthenticated]);
    // const isCommunity = typeof window.location.pathname === "string" && window.location.pathname.length > 5 && window.location.pathname.substring(0, 5) === "/edit/";
  if (isLoading && display) return <Grommet theme={customTheme} full  ><PageLoader /></Grommet>;

  return (

    <Grommet theme={customTheme} full  >

      {/* <Fragment style={{top:'50%', left:'50%', position:'fixed', transform:'translate(-50%, -50%)'}}> */}

      {/* For footer increase here paddingBottom*/}

      {/* <Page style={{ paddingBottom: "0vh", minHeight: "93vh" }}> */}

      <Page style={{ paddingBottom: "0vh", minHeight: "100vh" }} >
        <NavBarPage />
        {/* <DiscountBanner /> */}
        <Routes>
          <Route
            path="/"
            // element={<AuthenticationGuard
            //     component={DisplayPage}
            //   />}
            element={
              isAuthenticated ? <Navigate to="/dashboard" /> : <HomePageOneTime />
            }
          />
          <Route
            path="/dashboard"
            element={<AuthenticationGuard component={Dashboard} />}
          />
          <Route
            path="/members"
            // element={isAuthenticated ? <Members/> : <Navigate to="/" />}
            element={<AuthenticationGuard component={Members} />}
          />
          <Route
            path="/analytics"
            element={<AuthenticationGuard component={Analytics} />}
          />
          <Route
            path="/subscriptions"
            element={<AuthenticationGuard component={Analytics} />}
          />
          <Route
            path="/communities"
            element={<AuthenticationGuard component={CommunitiesPage} />}
          />
          <Route
            path="/transactions"
            element={<AuthenticationGuard component={Wallet} />}
          />
          <Route
            path="/not_found"
            element={<AuthErrorDisplayPage/>}
          />
          <Route path="/purchases" element={<SearchComponent />} />
          <Route
            path="/edit/*"
            element={<AuthenticationGuard component={CommunityEditPage} />}
          />
          
          <Route path="/paid" element={<PostPaymentPage />} />
          {/* Delet the above postPayment afterwards abhi_delete */}
          <Route path="/*" element={<CommunityPage />} />
          {/* <Route
                        path="/*"
                        element={<NotFoundPage/>}
                    /> */}
          <Route
            path="/policies/tnc"
            element={< TermsAndConditions messageCode="tnc"/>
          }
          />

          <Route
            path="/policies/privacy"
            element={< TermsAndConditions messageCode="privacy"/>}
          />
          
          <Route
            path="/policies/refund"
            element={< TermsAndConditions messageCode="refund"/>}
          />
        </Routes>

        {/* <Box
          // background="lightblue"
          pad="small"
          justify="between"
          align="center"
          direction="row"
          gap="large"
          fill
        >
          <Text
            size="small"
            color="#031635"
            textAlign="center"
            alignSelf="left"
          >
            Powered By © 2024 Membeed.com
          </Text>
          <Button size="small" color="#031635" textAlign="center" href="mailto:simplyitsabg@gmail.com">
            Feedback
          </Button>
        </Box> */}
        <Footer /> 
      </Page>
      {/* </Page> */}
    </Grommet>
  );
};

export default Home;
