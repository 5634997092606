import { Box, Button, Grommet, Header, ResponsiveContext, Text } from "grommet";
import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";



const Title = () => {
    const size = useContext(ResponsiveContext);
    const isMobile = size === "xsmall";
    const navigate = useNavigate();

    const homeLink = "/";

    const gotoHome = () => {
      navigate(homeLink);
      // setdashboardActive(false);
    };
    return (
        <Button onClick={gotoHome} alignSelf="start">
            <Text
                color={{ dark: "white", light: "black" }}
                size= {isMobile ? "medium" : "xlarge"}
                weight="normal"
                style={{
                    letterSpacing: "1.5px",
                    textShadow: "5px",
                }}
            >
                membeed
            </Text>
        </Button>

    );
}

export default Title;
