import React from "react";
import { Spinner, Box, Grommet, Image } from "grommet";
import { hpe } from "grommet-theme-hpe";

export const PageLoader = () => {
  // const backgroundImageUrl = 'https://res.cloudinary.com/ddordov7b/image/upload/v1717929099/membeed_hphikh.png';

  const backgroundImageUrl = 'https://res.cloudinary.com/ddordov7b/image/upload/v1718019551/membeed_logo1_vwf8ex.png';
  return (
    // <Grommet theme={hpe} full>
    <Box fill flex align="center" justify="center">
            <Box
      // fill
        align="center"
        // margin="large"
        justify="center"
                style={{
                    // maxHeight: "100px",
                    // maxWidth: "100px"
                    }}
                background={{
                image: `url(${backgroundImageUrl})`,
                size: 'contain',
                }}>
      <Spinner
        size="large"
        color="#031635"
          message={{
            start: "Loading data.",
            end: "Data has been loaded.",
          }}
      >
      </Spinner>
      {/* <Image src="https://res.cloudinary.com/ddordov7b/image/upload/v1717929099/membeed_hphikh.png"/> */}
    </Box>
    </Box>


    // </Grommet>
  );
};