import React, { useContext } from "react";
import { Box, Button, Text, Layer, Stack, ResponsiveContext } from "grommet";
import {
  Clock,
  StatusGood,
  StatusCritical,
  StatusWarning,
  StatusGoodSmall,
  RadialSelected,
  LocationPin,
  Disc,
  Emoji,
  Robot,
  Clear,
} from "grommet-icons";
import styled, { keyframes } from "styled-components";

const getStatusColor = (status) => {
  if (status === "Withdrawn" || status === "requested") return "darkgreen";
  if (status === "Failed") return "darkred";
  return "grey";
};

const getGradient = (status) => {
    // switch (status) {
    //   case 'Withdrawn':
    //     return 'linear-gradient(to bottom, green, green)';
    //   case 'Processing':
    //     return 'linear-gradient(to bottom, green, green)';
    if (status === "Processing") return 'linear-gradient(to bottom, green, grey)';
    if (status === "Failed") return 'linear-gradient(to bottom, green, red)';
    return 'linear-gradient(to bottom, green, #003b00)';
    // }
  };
  
  const CustomGradientLeftBorder = styled.div`
    border-left: 4px solid;
    border-image: ${({ status }) => getGradient(status)} 1 100%;
    width: 100px;
    height: 100px;
    margin-left: ${ ({isMobile}) => {if(isMobile) return "6px"; return "6px";}}
  `;

const getStatusIcon = (status) => {
    const colorToBeSet = getStatusColor(status);
  if (status === "Withdrawn") return <Emoji color={colorToBeSet}/>;
  if (status === "requested") return <RadialSelected color={colorToBeSet}  strokeWidth="4px"/>;
  if (status === "Failed") return <Clear color={colorToBeSet} />;
  return <Clock color={colorToBeSet} />;
};

const Blink = keyframes`
  0% { opacity: 0.1; }
  50% { opacity: 1; }
  100% { opacity: 0.1; }
`;

const BlinkingDot = styled(Clock)`
  animation: ${Blink} 1s infinite;
  color: green;
`;

const TransactionDetailsLayer = ({ transaction, onClose }) => {

    const size = useContext(ResponsiveContext);

    const isMobile = size === "xsmall";


    return (
  <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
    <Box pad="medium" gap="small" width="medium">
      <Box direction="row" justify="between" align="center">
        <Text size="large" weight="bold">
          Transaction Details
        </Text>
        <Button label="Close" onClick={onClose} />
      </Box>
      <Stack anchor="left">
        <Box margin={{ top: "small" }}>
          <Box direction="row" align="center" gap="small">
            {getStatusIcon("requested")}
            <Text>
              Requested At: {new Date(transaction.requestedAt).toLocaleString("en-IN")}
            </Text>
          </Box>
          {/* <Box
            height="100px"
            // border={{ color: getStatusColor(transaction.status), size: 'small' }}
            margin={{ left: isMobile ? "small" : "xsmall"}}
            border={[
              {
                size: "medium",
                side: "left",
                // color: getStatusColor(transaction.status),
              },
            ]}
            style={{
                borderImage : "linear-gradient(to bottom, green, red) 1 100%;"
            }}
          /> */}
          <CustomGradientLeftBorder status={transaction.status} isMobile={isMobile}/>
          <Box
            direction="row"
            align="center"
            gap="small"
            // margin={{ top: "small" }}
          >
            {transaction.status === "Processing" ? (
              <BlinkingDot size="small" />
            ) : (
              getStatusIcon(transaction.status)
            )}
            <Text>
              {transaction.status === "Withdrawn"
                ? `Withdrawn At: ${new Date(
                    transaction.processedAt
                  ).toLocaleString()}`
                : transaction.status === "Failed"
                ? `Failed At: ${new Date(
                    transaction.processedAt
                  ).toLocaleString()}`
                : "Pending Processing"}
            </Text>
          </Box>
        </Box>
        {transaction.status === "Failed" && (
          <Box
            direction="row"
            align="center"
            gap="small"
            margin={{ top: "small", left : "large"}}
          >
            <Text>Failure Reason: {transaction.failureReason}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  </Layer>
)
        };

export default TransactionDetailsLayer;
