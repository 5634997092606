import React from 'react';
import { Box, Text, Layer } from 'grommet';
import { Norton, Xing } from 'grommet-icons';
import styled, { keyframes } from 'styled-components';

// Define keyframes for custom animations
const scaleUp = keyframes`
  0% { transform: scale(0); }
  100% { transform: scale(1); }
`;

const swoopIn = keyframes`
  0% { transform: translateX(-100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
`;

const StyledIconBox = styled(Box)`
  animation: ${scaleUp} 0.5s ease-out;
`;

const StyledMessageBox = styled(Box)`
  animation: ${swoopIn} 0.7s ease-out;
`;

const DisplayOrderStatus = ({ message, status, onClose }) => {
  const displayMessage = message || (status ? 'Order placed' : 'Order could not be placed');
  const icon = status ? <Norton color="brand" size="large" /> : <Xing color="brand" size="large" />;
  const backgroundColor = status ? 'status-ok' : 'light-4';

  return (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}
      responsive={true}
      animation="slide"
    >
      <Box
        pad="medium"
        align="center"
        justify="center"
        background={backgroundColor}
        round="small"
        gap="medium"
        direction="row"
        responsive={true}
      >
        {status ? (
          <StyledIconBox>{icon}</StyledIconBox>
        ) : (
          <Box>{icon}</Box>
        )}
        {status ? (
          <StyledMessageBox>
            <Text size="large" weight="bold" style={{letterSpacing:"1.2px"}}color="white">
              {displayMessage}
            </Text>
          </StyledMessageBox>
        ) : (
          <Box>
            <Text size="large" weight="bold">
              {displayMessage}
            </Text>
          </Box>
        )}
      </Box>
    </Layer>
  );
};

export default DisplayOrderStatus;
