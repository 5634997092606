import React, { useState } from 'react';
import { Grommet, Box, Button, Form, FormField, Layer, TextInput, Text, Notification } from 'grommet';

const JoinCommunityForm = ({ community, planType, basicNeeds, amount, onSubmit, onClose, serverErrorMsg }) => {


  // console.log("Basic needs before reducing : ", basicNeeds);
  const defaultFormValues = basicNeeds.reduce((acc, key) => {
    if (key !== "Other" && key !== "") {
      acc[key] = '';
    }
    if (key === "Number") {
      acc[key] = ""
    }
    return acc;
  }, {});

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [formErrorMsg, setFormErrorMsg] = useState();
  const [amountPayable, setAmountPayable] = useState(amount);

  const emailValidation = [
    {
      regexp: new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"),
      message: "Enter a valid email address",
      status: "error",
    },
    // Cannot pass any async function here
  ];

  const phoneValidation = [
    {
      regexp: new RegExp("^\d{10}$"),
      message: "Enter a valid phone number",
      status: "error",
    },
    // Cannot pass any async function here
  ];

  const handleSubmit = ({ value }) => {
    // console.log("In join form : ", formValues, value);

    const numberPattern = /^\d{10}$/;
    // console.log(formValues?.Number)

    // console.log(numberPattern.test(formValues?.Number))
    // console.log(typeof formValues?.Number)
      // Clear previous errors
    setFormErrorMsg(null);
    if (formValues?.Number && !numberPattern.test(formValues?.Number) ) {
      setFormErrorMsg("Invalid Number");
      setTimeout(() => {
        setFormErrorMsg();
      }, 3000);
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;
    if (formValues?.Mail && !emailPattern.test(formValues?.Mail)) {
      setFormErrorMsg("Invalid Mail");
      setTimeout(() => {
        setFormErrorMsg();
      }, 3000);
      return;
    } 

    // For donation plan, validate the amount
    if (planType === "Donation" && (!formValues?.DonationAmount || parseFloat(formValues?.DonationAmount) <= 0)) {
      setFormErrorMsg("Please enter a valid donation amount greater than 0");
      setTimeout(() => setFormErrorMsg(null), 3000);
      return;
    }
    // For phone number validation

    //For email validation

    onSubmit(formValues);
  };

  // Function to dynamically update the amount payable based on the donation amount
  const handleDonationAmountChange = (value) => {
    setFormValues((prev) => ({ ...prev, DonationAmount: value }));
    if (parseFloat(value) > 0) {
      setAmountPayable(value); // Update the amount payable with the entered donation amount
    }else
    setAmountPayable(0);
  };

  const getLabeFullName = (name) => {
    if(name === "Mail"){
      return "Mail (Payment details will be sent to this mail)"
    }
    return name;
  }

  return (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}

    >
        {serverErrorMsg && ( // Show notification only if there's an error
        <Box align="center">
          <Notification
            toast={{
              autoClose: true,
              // position: "bottom-right",
            }}
            color="red"
            status="normal"
            message={<Text color="red">{serverErrorMsg}</Text>}
            time={5000}
          // onClose={handleCloseNotification}
          />
        </Box>
      )}
      {formErrorMsg && <Notification
        toast={{
          autoClose: true,
          // position: "bottom-right",
        }}
        color="red"
        status="normal"
        message={<Text color="red">{formErrorMsg}</Text>}
        time={7000}
      // onClose={handleCloseNotification}
      />}
      <Box pad="medium" gap="small" width="medium" overflow="auto">
        <Text size="large" weight="bold" alignSelf='center'>Join {community}</Text>
        <Text size="medium" alignSelf='center' margin={{ bottom: 'medium' }}>
          Amount payable: {amountPayable} INR
        </Text>
        <Form
          value={formValues}
          onChange={(nextValue) => {
            setFormErrorMsg(null);
            setFormValues(nextValue)}
          }
          onSubmit={handleSubmit}
        >
          {basicNeeds.map((field, index) => {
            if (typeof field === "string" && field !== "" && field !== "Other") {
              return <FormField
                key={index}
                name={field}
                label={getLabeFullName(field)}
                required
              >
                <TextInput name={field} maxLength={30} style={{ fontWeight: "normal" }} />
              </FormField>
            } 
          }

          )}
          {planType === "Donation" && (
            <FormField
              name="DonationAmount"
              label="Donation Amount"
              required
              validate={(value) => {
                if (!value || parseFloat(value) <= 0) {
                  return "Amount must be greater than 0";
                }
                return undefined;
              }}
            >
              <TextInput
                name="DonationAmount"
                type="number"
                min="0"
                onChange={(event) => handleDonationAmountChange(event.target.value)}
              />
            </FormField>
          )}
          <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
            <Button label="Cancel" onClick={onClose} />
            <Button type="submit" secondary label="Proceed to Checkout" />

          </Box>
        </Form>
      </Box>
    </Layer>
  );
};

export default JoinCommunityForm;
