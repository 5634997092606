import {
  Anchor,
  Box,
  DataTable,
  Page,
  PageHeader,
  Pagination,
  Select,
  Text,
  Button,
} from "grommet";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NothingToShow from "../components/nothingToShow";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { PageLoader } from "../components/pageLoader";
import * as XLSX from "xlsx";

const Members = () => {
  const { getAccessTokenSilently } = useAuth0();
  //neeeded for pagination
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); //By default show 10 members per page
  const [totalItems, setTotalItems] = useState(0);
  const [isMembersFetching, setIsMembersFetching] = useState(false);

  const [commId, setCommId] = useState("");
  const [comDetailsFetched, setComDetailsFetched] = useState([]);
  const [commsAvailable, setCommsAvailable] = useState([]);
  const [members, setMembers] = useState([]);
  const [preCheckoutUserInputs, setPreCheckoutUserInputs] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [exportType, setExportType] = useState(null);
  const contentTitle = "Members";

  const contentSubTitleTip =
    " You can message/remove/notify any of them via multiple platforms like discord, telegram, mail.";
  const contentSubTitle = "Manage your members";
  const nothingToShowMsg = "No one has joined the Community yet.";

  const navigate = useNavigate();
  const anchorClickHandler = () => {
    navigate(-1);
  };

  // Fetch communities for the authenticated user
  const fetchCommunities = async () => {
    setIsPageLoading(true);
    try {
      const access_token = await getAccessTokenSilently();
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          process.env.REACT_APP_ADMIN_SUBURL +
          "/sample@membeed.com/communities",
        {
          headers: {
            Authorization: "Bearer " + access_token,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }
      const response_json = response.data;
      // console.log("Each community data to find plan id", response_json )
      if (!response_json) {
        throw new Error("Invalid data fetched");
      }
      setComDetailsFetched(response_json);
      const commList = response_json
        .map((item) => {
          if (item.nameLink && item.nameLink.length > 1) {
            return item.nameLink.substring(1);
          }
          return null;
        })
        .filter(Boolean);
      setCommsAvailable(commList);
      if (commList.length > 0) {
        setCommId(commList[0]); // Set initial community ID
        await fetchMembers(commList[0]); // Fetch members for the initial community
      }
      setIsPageLoading(false);
    } catch (error) {
      console.error("Error fetching communities:", error);
      setIsPageLoading(false);
    }
  };

  const getPlanName = ({commLink, planId}) => {
    // console.log("commLink @ getPlanName : ", commLink);
    // console.log("planId @ getPlanName : ", planId);
    // console.log("comDetailsFetched @ getPlanName : ", comDetailsFetched);
    if(!commLink || !planId) return "NA";
    if(!comDetailsFetched) {
      // console.log("No comms fetched");
      return "NA";
    }

    for (let i = 0; i < comDetailsFetched.length; i++) {
      var commNameLink = comDetailsFetched[i].nameLink;
      if (commNameLink.startsWith("/")) commNameLink = commNameLink.substring(1);
      // console.log("compare links @ getPlanName : ", commNameLink, commLink)
      if(commNameLink === commLink){
        for (let j = 0; j < comDetailsFetched[i].plans.length; j++) {
          if (comDetailsFetched[i].plans[j].planObjectId === planId) {
            return comDetailsFetched[i].plans[j].planName;
          }
        }
      }
    }
    // console.log("Could not retrieve plan name");
    return "NA"
  }

  const onMoreHandler = async () => {
    // console.log("I need more data");
  };

  // Fetch members based on selected community
  const fetchMembers = async (communityId) => {
    // setIsPageLoading(true);
    setIsMembersFetching(true);
    try {
      const access_token = await getAccessTokenSilently();
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          `/api/community/${communityId || commId}/subscribers`,
        {
          headers: {
            Authorization: "Bearer " + access_token,
          },
          params: {
            page,
            limit: itemsPerPage,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }
      // console.log("Frontend data retrieved:", response.data);
            // setting userinput and members
      setMembers(response.data.subscribers);
      setPreCheckoutUserInputs(response.data.preCheckoutUserInputs);
      setTotalItems(response?.data?.totalItems || 0); //set total items fetched by api
    } catch (error) {
      console.error("Error fetching members:", error);
      setMembers([]);
    } finally {
      // setIsPageLoading(false);
      setIsMembersFetching(false);
    }
  };

  // Fetch communities on component mount
  useEffect(() => {
    fetchCommunities();
  }, []);

  // Fetch Members on page change by user or rendering
  useEffect(() => {
    fetchMembers();
  }, [page]);

  // Handle community change
  const handleCommunityChange = ({ value: nextValue }) => {
    // console.log(nextValue);
    setCommId(nextValue);
    setPage(1); //set to default to page
    fetchMembers(nextValue);
  };

  if (commsAvailable.length === 0 && isPageLoading) return <PageLoader />;

  // Create dynamic columns
  const dynamicColumns = (preCheckoutUserInputs.basicDetails || []).map(
    (detail) => ({
      property: detail,
      header: <Text>{detail}</Text>,
    })
  );

  // Add the extra column "Plan Name"
  const planNameColumn = {
    property: "planName",
    header: <Text>Plan Name</Text>,
    render: (datum) => {
      // console.log("datum @ planNameColumnrender :", datum);
      return (
        <Text>
          {getPlanName({
            commLink: datum?.community?.namelink,
            planId: datum?.community?.planObjectId,
          })}
        </Text>
      );
    },
  };

  // Add Donation Amount column if it exists in the data
  const donationAmountColumn = {
    property: "donationAmount",
    header: <Text>Donation Amount</Text>,
    render: (datum) =>
      datum?.DonationAmount ? `₹${datum.DonationAmount}` : "N/A",
  };

  // Add dynamic columns for each social ID
  if (
    preCheckoutUserInputs.socialIds &&
    Array.isArray(preCheckoutUserInputs.socialIds)
  ) {
    preCheckoutUserInputs.socialIds.forEach((socialId) => {
      dynamicColumns.push({
        property: socialId.toLowerCase(),
        header: <Text>{socialId}</Text>,
        render: (datum) => (datum[socialId] ? datum[socialId] : "N/A"),
      });
    });
  }

  // Insert the extra column at a specific position (e.g., position 1)
  const finalColumnsToDisplay = [
    ...dynamicColumns,
    planNameColumn,
    donationAmountColumn,
  ];

  const exportData = () => {
    console.log("printing", exportType);
    if (exportType.value === "excel") {
      console.log("printing");
      exportToExcel();
    }
    // } else if (exportType.value === 'pdf') {
    //   exportToPDF();
    // } else if (exportType.value === 'word') {
    //   exportToWord();
    // }
  };

  const exportToExcel = () => {
    console.log("Exporting to Excel with data:", members); // Add this line

    const filteredData = members.map((member) => ({
      Name: member.Name, // Assuming 'name' is the property for the Name field
      Number: member.Number, // Assuming 'number' is the property for the Number field
      DonationAmount: member.DonationAmount || "N/A", // Assuming DonationAmount is the correct property
    }));

    // Create a worksheet with the filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Members");
    XLSX.writeFile(workbook, "members.xlsx");
  };

  return (
    <Page flex>
      <PageHeader
        title={contentTitle}
        subtitle={contentSubTitle}
        pad="medium"
        parent={<Anchor label="Back" onClick={anchorClickHandler} />}
        actions={
          <Select
            id="select"
            name="select"
            color="#031635"
            dropProps={{
              color: "#031635",
              background: "light-2",
              children: {
                style: {
                  color: "#031635",
                  background: {
                    color: "#031635",
                  },
                },
              },
            }}
            placeholder="Select"
            labelKey="nameLink"
            valueKey={{ key: "_id", reduce: true }}
            value={commId}
            options={commsAvailable}
            onChange={handleCommunityChange}
          />
        }
      />
      {!isPageLoading ? (
        members.length === 0 ? (
          <NothingToShow message={nothingToShowMsg} />
        ) : (
          <Box margin="large" overflow="scroll">
            {isMembersFetching ? (
              <PageLoader />
            ) : (
              <DataTable
                columns={finalColumnsToDisplay}
                data={members}
                step={itemsPerPage}
                primaryKey="_id"
              />
            )}
            {!(page === 1 && isMembersFetching) && (
              <Pagination
                numberItems={totalItems}
                step={itemsPerPage}
                page={page}
                onChange={({ page }) => setPage(page)}
              />
            )}

            <Box
              direction="row"
              gap="small"
              align="center"
              justify="flex-end"
              justify-content="flex-end"
              content="flex-end"
              pad="small"
              background="light-2"
              round="small"
              margin={{ top: "medium" }} // Adjust margin to give space
            >
              <box>
                <Text>Export as </Text>
                <Select
                  options={[{ label: "Excel", value: "excel" }]}
                  value={exportType}
                  onChange={({ value }) => setExportType(value)}
                  placeholder="Export as"
                  dropProps={{ background: "light-1" }}
                />
              </box>

              <Button
                label="Download"
                onClick={exportData}
                disabled={!exportType}
                primary
              />
            </Box>
          </Box>
        )
      ) : (
        <PageLoader />
      )}
    </Page>
  );
};

export default Members;
