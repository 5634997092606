import React, { useContext, useState } from "react";
import {
  Box,
  Form,
  FormField,
  TextInput,
  Select,
  Button,
  Text,
  CheckBoxGroup,
  SelectMultiple,
  PageHeader,
  ResponsiveContext,
  TextArea,
  CheckBox,
} from "grommet";
import {
  Add,
  Trash,
  Mail,
  Instagram,
  Telegram,
  Discord,
  Facebook,
  Twitter,
  LinkedIn,
  Snapchat,
  WhatsApp,
} from "grommet-icons";

const SubscriberForm = ({
  basicNeeds,
  setBasicNeeds,
  platforms,
  setPlatforms,
  otherId,
  setOtherId,
  checkPostFormData,
  setCheckPostFormData,
}) => {
  const [formNeeds, setFormNeeds] = useState([]);
  const size = useContext(ResponsiveContext);
  // const [checkPostFormData, setCheckPostFormData] = useState({
  //   redirectURI: "",
  //   isRedirect: false,
  //   checkoutMsg: "",
  // });
 console.log("Nagendra ",basicNeeds)

// Change if any number to be made
  const platformOptions = [
    "Instagram",
    "Telegram",
    "Discord",
    "Facebook",
    "Twitter",
    "LinkedIn",
    "Snapchat",
    "WhatsApp",
    "Mail",
    "Other",
  ];
  const defaultNamePhnoAndMail = ["Name", "Number","Mail"];

  const [errors, setErrors] = useState({});

  const handleBasicNeedsChange = (selectedOptions) => {
    // const selectedOptions = [];
    // console.log("selected options : ", selectedOptions);
    // setNameAndPh(selectedOptions);
    // const newNeeds = [...selectedOptions, ...platforms, otherId];
    setBasicNeeds(selectedOptions);
  };

  const handlePlatformChange = (value) => {
    // console.log("selected updatedPlatforms : ", value);
    // const appendedValues = basicNeeds.push(...value);
    setPlatforms(value);
    // setBasicNeeds(...value, ...nameAndPh, otherId);
  };

  const handleUserIdChange = (event) => {
    // console.log("handleUserIdChange : ", event.target.value);
    setOtherId(event.target.value);
    // setBasicNeeds(value, ...nameAndPh, ...platforms);
  };

  const addPlatform = () => {
    setErrors({});
    if (platforms.length < 10) {
      setPlatforms([...platforms, { platform: "", userId: "" }]);
    } else {
      setErrors({ maxPlatforms: "Maximum of 10 platforms allowed." });
    }
  };

  const removePlatform = (index) => {
    const updatedPlatforms = [...platforms];
    updatedPlatforms.splice(index, 1);
    setPlatforms(updatedPlatforms);
    setErrors({});
  };

  // console.log("platforms : ", platforms);
 

  return (
    <Box justify="center" align="center">
      <Box direction="row-responsive" gap="large">
        <Box align="center" pad="medium" flex>
          <Text size={size}>Pre-checkout Input</Text>
          <Form value={{
            basicNeeds: defaultNamePhnoAndMail, //abhi_intentionally given to opt fot min of name and number
            socialIds: platforms,
            otherSocialId : otherId,
          }} validate="blur" errors={errors} label="Pre-checkout Input">
            <Box margin={{ bottom: "medium" }}>
              <FormField
                // required
                name="basicNeeds"
                label="Basic Inputs from User"
                // disabled
              >
                <CheckBoxGroup
                  name="basicNeeds"
                  validate={
                    [
                      (value) => {
                        if (!value) {
                          return "Needed";
                        }
                        // console.log("value in validate in basicNeeds : ", value);
                      }
                    ]
                  }
                  required
                  options={defaultNamePhnoAndMail}
                  // value={basicNeeds}
                  onChange={({ value }) => {
                    // console.log(" onChange basicPhName : ",  value);
                    handleBasicNeedsChange(value);
                  }}
                  disabled
                />
              </FormField>
              <FormField name="socialIds" label="Choose Needed IDs" >
                <SelectMultiple
                  limit={2}
                  name="socialIds"
                  // value={platforms}
                  options={platformOptions}
                  dropAlign={{
                    bottom: "top",
                  }}
                  // value={basicNeeds}
                  onChange={({ value }) => {
                    handlePlatformChange(value);
                  }}
                  
                />
              </FormField>
              {platforms.includes("Other") && (
                <FormField name="otherSocialId" label="Other" required>
                  <TextInput
                    name="otherSocialId"
                    // value={otherId}
                    onChange={handleUserIdChange}
                  />
                </FormField>
              )}
            </Box>
          </Form>
        </Box>

        {/* For Post Checkout Options */}
        <Box align="center" pad="medium" flex>
          <Text size={size}>Post-checkout Input</Text>
          <Form value={checkPostFormData} onChange={setCheckPostFormData}>
            <FormField
              label="Message to be shown after payments"
              name="checkoutMsg"
            >
              <TextArea
                name="checkoutMsg"
                maxLength={250}
                size="small"
                placeholder="Thanks for your purchase!"
                style={{ fontSize: "medium", fontWeight: "normal" }}
              ></TextArea>
            </FormField>
            <FormField name="isRedirect">
              <CheckBox name="isRedirect" label="Redirect to a Link"></CheckBox>
            </FormField>
            {checkPostFormData.isRedirect && (
              <FormField
                label="Redirect URL"
                name="redirectURI"
                maxLength={750}
              >
                <TextInput
                  name="redirectURI"
                  style={{ fontSize: "medium", fontWeight: "normal" }}
                  placeholder="https://example.com/home"
                ></TextInput>
              </FormField>
            )}
          </Form>
        </Box>
      </Box>
      {/* <Button
            secondary
        style={{ width: "100%", maxWidth:"150px" }}
        
            type="submit"
            label="Submit"
          /> */}
    </Box>
  );
};

export default SubscriberForm;
