import React from "react";
import NotFoundPage from "../components/notFound";
import { Box, Button, Page } from "grommet";
import { useNavigate } from "react-router-dom";


const AuthErrorDisplayPage = () => {
    const displayError = "something went wrong!";
    const navigate = useNavigate();
    const retryHandler = () => {
        navigate("/");
    }
    return (
        <Page flex>
            <Box height="small" align="center">
            <NotFoundPage message={displayError}/>
            <Button size="medium" style={{
                width : "fit-content"
            }}secondary label="Retry" onClick={retryHandler}/>
            </Box>

        </Page>
    )
}
export default AuthErrorDisplayPage;
