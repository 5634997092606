import React, { useContext, useState, useEffect } from 'react';
import { Box, Card, CardBody, CardHeader, Page, PageHeader, Anchor, Select, Text, Button, ResponsiveContext } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { useSpring, animated } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import { CurrencyRupee, Group } from '@mui/icons-material';
import NothingToShow from '../components/nothingToShow';
import WithdrawFunds from '../components/withdrawFunds';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "../components/pageLoader";
import axios from "axios";

const AnimatedNumber = ({ value, prefix = "" }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: value,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return (
    <animated.div>
      {number.to((n) => `${prefix}${Math.round(n).toLocaleString('en-IN')}`)}
    </animated.div>
  );
};

const CardComponent = ({ title, value, prefix, icon, size }) => (
  <Card background="light-1" margin="small" elevation="medium" flex="grow">
    <CardHeader pad="small" background="graph-1" direction="row" align="center" gap="small">
      {icon}
      <Text style={{ lineHeight: "0.5rem" }} size={size} weight="bold" color="white">{title}</Text>
    </CardHeader>
    <CardBody pad="small" align='center'>
      <Text size={size} style={{ lineHeight: "0.5rem" }}>
        <AnimatedNumber value={value} prefix={prefix} />
      </Text>
    </CardBody>
  </Card>
);

const Analytics = () => {
  const size = useContext(ResponsiveContext);
  const isMobile = size === "xsmall";
  const { getAccessTokenSilently } = useAuth0();

  const [commsAvailable, setCommsAvailable] = useState([]);
  const [commId, setCommId] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showWithdrawalLayer, setShowWithdrawalLayer] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [membersData, setMembersData] = useState({
    totalMembers: 0,
    activeMembers: 0,
  });
  const [amountData, setAmountsData] = useState({
    totalAmount: 0,
    withDrawnAmount: 0,
    availableAmount: 0
  });

  const fetchAvailableAmount = async (communityId) => {
    setIsPageLoading(true);
    // console.log("Need to fetch ");

    let getAmountsURL = process.env.REACT_APP_BACKEND_URL + "/api/admin/communityPayments";

    if (communityId === "all") {
        getAmountsURL += "?combined=true";
    } else {
        getAmountsURL += `?commLink=${communityId}`;
    }
    
    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: getAmountsURL,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
        }
    };

    // console.log("config to fetch ", config);


    try {
        const response = await axios.request(config);
        if (response) {
            if (response.status === 200) {
                const responseData = response.data;
                setAmountsData({totalAmount: responseData.totalAmount,
                  withDrawnAmount: responseData.totalAmount-responseData.availableAmount,
                  availableAmount: responseData.availableAmount });
                // console.log(responseData,communityId)
            }
        } else {
            throw new Error("No Response ERROR");
        }
    } catch (error) {
        // console.log(error);
        setAmountsData({totalAmount: 0,
          withDrawnAmount: 0,
          availableAmount: 0});
          return "server_error";
    } finally {
        setIsPageLoading(false);
    }
};


  const fetchCommunities = async () => {
    setIsPageLoading(true);
    try {
      const access_token = await getAccessTokenSilently();
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
        process.env.REACT_APP_ADMIN_SUBURL +
        "/sample@membeed.com/communities",
        {
          headers: {
            Authorization: "Bearer " + access_token,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }
      const response_json = response.data;
      if (!response_json) {
        throw new Error("Invalid data fetched");
      }
      const commList = response_json.map((item) => {
        if (item.nameLink && item.nameLink.length > 1) {
          return item.nameLink.substring(1);
        }
        return null;
      }).filter(Boolean);

      // Add default "All" option
      const commsWithAllOption = [{ label: "All", value: "all" }, ...commList.map(comm => ({ label: comm, value: comm }))];
      setCommsAvailable(commsWithAllOption);
      if (commsWithAllOption.length > 0) {
        setCommId(commsWithAllOption[0].value); // Set initial community ID to "All"
        fetchMembers(commsWithAllOption[0].value); // Fetch members for the initial community
        fetchAvailableAmount(commsWithAllOption[0].value);  //Fetch amount for all default option
      }
      setIsPageLoading(false);
    } catch (error) {
      console.error("Error fetching communities:", error);
      setIsPageLoading(false);
    }
  };

  const fetchMembers = async (communityId) => {
    setIsPageLoading(true);
    try {
      const access_token = await getAccessTokenSilently();
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
        `/api/community/${communityId}/subscribers?count=true`,
        {
          headers: {
            Authorization: "Bearer " + access_token,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to fetch data");
      }
      // console.log(response.data["subscribers"].length)
      const [ totalMembers, activeMembers ] = [response.data["subscribersCount"],response.data["subscribersCount"] ];
      // console.log(totalMembers ,activeMembers)
      setMembersData({ totalMembers, activeMembers });
      setIsPageLoading(false);
    } catch (error) {
      console.error("Error fetching members:", error);
      setIsPageLoading(false);
      setMembersData({ totalMembers: 0, activeMembers: 0 });
    }
  };

  useEffect(() => {
    if(commId){
      fetchAvailableAmount(commId);
    }
  }, [commId]);

  useEffect(() => {
    fetchCommunities();
  }, []);

  useEffect(() => {
    if (commId) {
      fetchMembers(commId);
    }
  }, [commId]);

  const withdrawHandler = () => {
    setShowWithdrawalLayer(true);
  };

  const navigate = useNavigate();
  const anchorClickHandler = () => {
    navigate(-1);
  };

  const mobileTitleTextStyle = {
    direction: size == "xsmall" ? "column" : "row",
  };

  const contentTitle = "Analytics";
  const contentSubTitle = "Know all about your community, how many visited your page, dropped at payment, total vs active, current vs payout balance, earned till now and pie charts and graphs";

  return (
    <Page>
      <PageHeader
        title={contentTitle}
        subtitle={contentSubTitle}
        pad="medium"
        parent={<Anchor label="Back" onClick={anchorClickHandler} />}
        actions={
          <Box gap="small">
            <Select
              id="select"
              name="select"
              color="#031635"
              dropProps={{
                color: "#031635",
                background: 'light-2',
                children: {
                  style: {
                    color: '#031635',
                    background: {
                      color: "#031635",
                    },
                  },
                },
              }}
              placeholder="Select"
              labelKey="label"
              valueKey={{ key: 'value', reduce: true }}
              value={commId}
              options={commsAvailable}
              onChange={({ value: nextValue }) => setCommId(nextValue)}
              onClose={() => setCommsAvailable(commsAvailable)}
              onSearch={(text) => {
                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                const exp = new RegExp(escapedText, 'i');
                setCommsAvailable(commsAvailable.filter((o) => exp.test(o)));
              }}
            />
            <Button onClick={withdrawHandler} busy={isButtonLoading} size={isMobile ? "xsmall" : "medium"} pad={{ horizontal: isMobile ? "none" : "small" }} secondary label="Withdraw" />
          </Box>
        }
      />

      <Box pad="large" gap="medium" justify="center"  {...mobileTitleTextStyle}>
        {/* Total Amount Section */}
        <Box basis="1/2" justify="center" align-items="stretch" height="medium">
          <Card background="light-1" margin="small" elevation="medium" flex>
            <CardHeader pad="medium" background="graph-1" direction="row" align="center" gap="small" justify='center'>
              <CurrencyRupee style={{ color: 'white' }} />
              <Text size={isMobile ? "normal" : "large"} weight="bold" color="white" style={{ lineHeight: "0.5rem" }} >Total Amount Earned</Text>
            </CardHeader>
            <CardBody pad="medium" align="center" >
              <Text size={isMobile ? "medium" : "large"} style={{ lineHeight: "0.5rem" }}>
                <AnimatedNumber value={amountData.totalAmount} prefix="₹" />
              </Text>
              <Box direction={(size === "xsmall" || size === "small" || size === "medium") ? "column" : "row"} justify="center" margin={{ top: 'medium' }} gap="medium" >
                <CardComponent title="Withdrawn Amount" size={isMobile ? "small" : "large"} value={amountData.withDrawnAmount} prefix="₹" icon={<CurrencyRupee style={{ color: 'white' }} />} />
                <CardComponent title="Amount Available" size={isMobile ? "small" : "large"} value={amountData.availableAmount} prefix="₹" icon={<CurrencyRupee style={{ color: 'white' }} />} />
              </Box>
            </CardBody>
          </Card>
        </Box>

        {/* Members Section */}
        <Box basis="1/2" justify="center" height="medium">
          <Card background="light-1" margin="small" elevation="medium" flex>
            <CardHeader pad="medium" background="graph-1" direction="row" align="center" gap="small" justify='center'>
              <Group style={{ color: 'white' }} />
              <Text size={isMobile ? "medium" : "large"} style={{ lineHeight: "0.5rem" }} weight="bold" color="white">Members</Text>
            </CardHeader>
            <CardBody pad="medium" align="center">
              <Box direction={(size === "xsmall" || size === "small" || size === "medium") ? "column" : "row"} justify="center" margin={{ top: 'medium' }} gap="medium">
                <CardComponent title="Total Members" size={isMobile ? "small" : "large"} value={membersData.totalMembers} icon={<Group style={{ color: 'white' }} />} />
                <CardComponent title="Active Members" size={isMobile ? "small" : "large"} value={membersData.activeMembers} icon={<Group style={{ color: 'white' }} />} />
              </Box>
            </CardBody>
          </Card>
        </Box>
      </Box>

      {showWithdrawalLayer && <WithdrawFunds showLayer={showWithdrawalLayer} setShowLayer={setShowWithdrawalLayer} />}
    </Page>
  );
};

export default Analytics;
