import { Box, Button, Card, CardBody, CardFooter, CardHeader, Grommet, Header, Text, TextInput } from "grommet";
import { Add } from "grommet-icons";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment, useState } from "react";


const AddPerkPoint = () => {

    const [newPoint, setNewPoint] = useState("");
    const handleAddPoint = () => { };
    return (
        <Box direction="row" align="center">
            <TextInput
                // type="text"
                value={newPoint}
                onChange={e => setNewPoint(e.target.value)}
                placeholder="New Point"
                style={{ marginRight: '8px' }}
            />
            <Button icon={<Add />} onClick={handleAddPoint} />
        </Box>
    );
}

export default AddPerkPoint;