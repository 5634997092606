import {
  Anchor,
  Box,
  Button,
  CheckBoxGroup,
  FileInput,
  Form,
  FormField,
  Grid,
  Grommet,
  Header,
  Image,
  Layer,
  Notification,
  Page,
  PageHeader,
  ResponsiveContext,
  Text,
  TextInput,
} from "grommet";
import { hpe } from "grommet-theme-hpe";
// import Compress from 'compress.js';
import imageCompression from 'browser-image-compression';
// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
import NotFoundPage from "../components/notFound";
import SubscriberCard from "../components/subscriberCard";
import SubcriptionForms from "../components/subscriptionForms";
import SubscriberForm from "../components/subscriberForms";
import ColorPicker from "../components/colorPicker";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "../components/pageLoader";
import axios from "axios";
// import { useAuth0 } from "@auth0/auth0-react";

function generateUniqueId(length = 12) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let uniqueId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueId += characters[randomIndex];
  }
  return uniqueId;
}

const backgroundImageUrl =
  "https://res.cloudinary.com/ddordov7b/image/upload/v1718089844/image_placeholder_fx24u2.png";

const defaultDetails = {
  nameLink: window.location.pathname,
  basicDetails: {
    commTitleName: "Community Title",
    commTitleColor: "#ffffff",
    commTitleBGColor: "#031635",
    commProfilePic: backgroundImageUrl,
  },
  plans: [
    {
      planObjectId: generateUniqueId(),
      planName: "Basic Plan",
      currency: "INR ₹",
      price: 100,
      period: "One Time",
      points: ["Feature 1", "Feature 2"],
      totalSeats: 0,
      availableSeats: 0,
      discountAvailable: false,
      discountMembers: 0,
      seatsAvailable: false,
    },
  ],
  checkoutDetails: {
    preCheckoutUserInputs: {
      basicDetails: ["Name", "Number"],
      socialIds: [],
      otherId: "",
    },
    postCheckoutDetails: {
      isRedirect: true,
      redirectURI: "http://example.com/redirect",
      checkoutMsg: "Thank you for your purchase!",
    },
  },
  subscriberUsers: [],
};

const validateCommunityData = (data) => {
  const errors = [];
  // console.log("validating data : ", data);
  if (
    !data.nameLink ||
    typeof data.nameLink !== "string" ||
    !data.nameLink.match(/^\/[a-zA-Z0-9_]*$/)
  ) {
    errors.push("nameLink is invalid.");
    return "Not a valid URL";
  }
  if (!data.basicDetails) {
    errors.push("basicDetails is required.");
    return "Not a valid title";
  }
  if (data.basicDetails) {
    // console.log("Recievd basicDetails : ", data.basicDetails);
    if (
      !data.basicDetails.commTitleName ||
      data.basicDetails.commTitleName === ""
    ) {
      errors.push("TitleName is required.");
      return "Title Name is required.";
    }
    if (!data.basicDetails.commTitleColor) {
      errors.push("commTitleColor is required.");
      return "Title Color is required.";
    }
    if (!data.basicDetails.commTitleBGColor) {
      errors.push("commTitleBGColor is required.");
      return "Title Background is required.";
    }
  }
  if (
    !data.plans ||
    !Array.isArray(data.plans) ||
    data.plans.length === 0 ||
    data.plans.length > 3
  ) {
    errors.push("Invalid plans");
    return "Invalid plans";
  }

  // Plan detailed check here add if any

  if (data.plans && Array.isArray(data.plans)) {
    for (let i = 0; i < data.plans.length; i++) {
      const plan = data.plans[i];
      // console.log("Plans display", plan)
      if (!plan.planName || plan.planName === "") {
        return `Plan name is required for plan ${i + 1}.`;
      }
      if (!plan.currency || plan.currency === "") {
        return `Currency is required for plan ${i + 1}.`;
      }
      if (plan.price === null || plan.price === "" ) {
        return `Price is required for plan ${i + 1}.`;
      }
      // console.log(typeof plan.price)
      if ( plan.price < 0) {
        return `Invalid value for Price in plan ${i + 1}.`;
      }
      if (!plan.period || plan.period === "") {
        return `Period is required for plan ${i + 1}.`;
      }

      // this error handled for the postman
      if (plan.price == 0 && plan.discountPercentage!=0 && plan.discountMembers!=0)
      {
        return `Discount can't be applied to the price zero for plan ${i+1}.`
      }

      if (plan.discountMembers > plan.totalSeats){
        return  `Number of members available for the discount should be less than total members for plan ${i+1}.`
      }
    
      if (
        !plan.points ||
        !Array.isArray(plan.points) ||
        plan.points.length === 0 ||
        plan.points.length > 5
      ) {
        return `Points are not valid for plan ${i + 1}.`;
      }
      if (plan.points[0] === "") {
        return `Minimum point is required plan ${i + 1}.`;
      }

      // add checks for limited members and discount 
    }
  } else {
    errors.push("Not a valid plans array");
    return "Invalid Plans";
  }
  if (data.checkoutDetails) {
    // console.log("inside checkoutDetails");
    if (!data.checkoutDetails.preCheckoutUserInputs) {
      // console.log("no preCheckout");
      errors.push("Pre checkout User Inputs is required.");
      return "Pre checkout User Inputs is required.";
    } else {
      // console.log("yes preCheckout");
      if (
        !data.checkoutDetails.preCheckoutUserInputs.basicDetails ||
        !Array.isArray(
          data.checkoutDetails.preCheckoutUserInputs.basicDetails
        ) ||
        data.checkoutDetails.preCheckoutUserInputs.basicDetails.length === 0
      ) {
        errors.push("invalid basicDetails");
        return "Select either Name or Number";
      }
      if (
        !data.checkoutDetails.preCheckoutUserInputs.socialIds ||
        !Array.isArray(data.checkoutDetails.preCheckoutUserInputs.socialIds)
      ) {
        errors.push("invalid socialIds");
        return "Invalid Social IDs";
      }
      if (
        data.checkoutDetails.preCheckoutUserInputs.socialIds.includes(
          "Other"
        ) &&
        (!data.checkoutDetails.preCheckoutUserInputs.otherId ||
          typeof data.checkoutDetails.preCheckoutUserInputs.otherId !==
          "string")
      ) {
        errors.push("invalid otherId");
        console.log(data.checkoutDetails.preCheckoutUserInputs.otherId);
        return "Invalid Social ID (Other)";
      }
    }
    if (!data.checkoutDetails.postCheckoutDetails) {
      errors.push("postCheckoutMsg is required in checkoutDetails.");
      return "postCheckoutMsg is required in checkoutDetails.";
    } else {
      const { isRedirect, redirectURI, checkoutMsg } =
        data.checkoutDetails.postCheckoutDetails;
      if (typeof isRedirect !== "boolean") {
        errors.push("Invalid type for 'isRedirect'. Expected boolean.");
        console.log("type of isRedirect ", typeof isRedirect);
        return "Invalid type for 'Redirect to a Link'";
      }

      if (typeof redirectURI !== "string" || (isRedirect && redirectURI === "")) {
        errors.push("Invalid type for 'redirectURI'. Expected string.", redirectURI, !redirectURI, typeof redirectURI !== "string", (isRedirect && redirectURI === ""));
        console.log(errors);
        return "Invalid type for 'redirect URL'.";
      }

      if (typeof checkoutMsg !== "string" || checkoutMsg === "") {
        errors.push("Invalid type for 'checkoutMsg'. Expected string.");
        return "Invalid type for 'checkoutMsg'.";
      }
    }
  } else {
    errors.push("No checkoutDetails");
    return "No checkoutDetails";
  }
  return null;
};


const validateMembercount = (data) => {

  if (data.plans && Array.isArray(data.plans)) {
    for (let i = 0; i < data.plans.length; i++) {
      const plan = data.plans[i];
      // console.log("plan totalSeats", plan.totalSeats)

      if (plan.totalSeats === 0) {
        return `Total Members/Seats should be greater than 0 for plan ${i + 1}.`;
      }
    }
  }
  return null;
  };

const CommunityEditPage = () => {
  const size = React.useContext(ResponsiveContext);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [pageDetails, setPageDetails] = useState(defaultDetails);

  // Editing the community Page - 3 scenes
  //  1. Invalid user landing on this page - what to do
  //        - if the page is already owned by someone, dont allow - even if he is authenticated -
  //        - if not authenticated yes, if not now - IGNORE will be handled by auth0 and backend API
  //  2. Actual user landing
  //      2.a - Creating - First Time
  //      2.b - Editing -

  const { getAccessTokenSilently } = useAuth0();

  // console.log("window.location : ", window.location);
  // console.log("window.location.pathname : ", window.location.pathname);

  const [invalidAccess, setInvalidAccess] = useState();

  // send userID, and communityLink
  // if(valid(user)){
  //      if getComm(communityLink) - editing 200, details
  //      else creating allow - creting 201, "allow"
  // }
  // else  - 400 bad request

  const getCommDetails = async () => {
    setIsPageLoading(true);
    let queryObject = {
      query: "details",
    };

    const queryString = new URLSearchParams(queryObject).toString();

    const adminURL =
      process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_ADMIN_SUBURL;

    const fullURL =
      adminURL +
      "/" +
      "sample@membeed.com/communities" +
      validatedName +
      "?" +
      queryString;

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fullURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }
        const response_json = response.data;
        if (!response_json) {
          throw new Error("invalid data fetched");
        }
        // console.log("User Comm fetched : ", response_json);
        if (response_json === "Invalid User") {
          // Major Validation, user should not be allowed to edit this page
          setInvalidAccess("Community is owned by someone else.");
        } else if (response_json === "Inactive") {
          // No comm exists right, so using default options
        } else {
          if (!validateCommunityData(response_json)) {
            // console.log("Updating everythin");
            const newPageDetails = response_json;
            setPageDetails(response_json);
            setTitleForm({
              titleName: newPageDetails.basicDetails.commTitleName,
            });
            setCommTitleColor(newPageDetails.basicDetails.commTitleColor);
            setCommPageColor(newPageDetails.basicDetails.commTitleBGColor);
            setProfileURL(
              newPageDetails.basicDetails.commProfilePic || backgroundImageUrl
            );
            setCommPlans(newPageDetails.plans);
            setBasicNeeds(
              newPageDetails.checkoutDetails.preCheckoutUserInputs.basicDetails
            );
            setPlatforms(
              newPageDetails.checkoutDetails.preCheckoutUserInputs.socialIds
            );
            setOtherId(
              newPageDetails.checkoutDetails.preCheckoutUserInputs.otherId
            );
            setCheckPostFormData(
              newPageDetails.checkoutDetails.postCheckoutDetails
            );
          }
        }
        setIsPageLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const putCommDetails = async (commPageDetails) => {
    setIsButtonLoading(true);
    // let queryObject = {
    //   query: "details",
    // };
    // console.log("Printing comm details now", commPageDetails)
    // const queryString = new URLSearchParams(queryObject).toString();

    const commURL =
      process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_COMM_SUBURL;

    // const fullURL = adminURL ;

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: commURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
      data: commPageDetails,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 201) {
          setApiNote("Page Updated Successfully");
          setFormError();
        } else {
          setFormError("Could not update the page.");
          setApiNote();
        }
        setIsButtonLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setIsButtonLoading(false);
      });
  };

  // const { getAccessTokenSilently } = useAuth0();
  // const { isAuthenticated } = useAuth0();

  // const [username, Setname] = React.useState('None')
  // let loginState = (isAuthenticated ?  "True" : "False" ); //Delete it after use

  // console.log(username);
  // return (
  //     <h1>{username}</h1>
  // );

  const formRef = useRef(null);
  const commName = window.location.pathname;

  const commTitleSize = size;

  const [dropBoxOpen, setDropBoxOpen] = useState(false);
  const [titleForm, setTitleForm] = useState({
    titleName: pageDetails.basicDetails.commTitleName,
  });

  const [commTitleColor, setCommTitleColor] = useState(
    pageDetails.basicDetails.commTitleColor
  );
  const [commPageColor, setCommPageColor] = useState(
    pageDetails.basicDetails.commTitleBGColor
  );
  const [profileURL, setProfileURL] = useState(
    pageDetails.basicDetails.commProfilePic || backgroundImageUrl
  );
  const [commPlans, setCommPlans] = useState(pageDetails.plans);
  const [basicNeeds, setBasicNeeds] = useState(
    pageDetails.checkoutDetails.preCheckoutUserInputs.basicDetails
  );
  const [platforms, setPlatforms] = useState(
    pageDetails.checkoutDetails.preCheckoutUserInputs.socialIds
  );
  const [otherId, setOtherId] = useState(
    pageDetails.checkoutDetails.preCheckoutUserInputs.otherId
  );
  const [checkPostFormData, setCheckPostFormData] = useState(
    pageDetails.checkoutDetails.postCheckoutDetails
  );

  const [formError, setFormError] = useState(null); // for displaying form error
  const [apiNote, setApiNote] = useState(null);

  const regex = /^\/edit\/[a-zA-Z0-9_]*$/;
  const validatedName = commName.match(regex)
    ? commName.substring(5)
    : "Error : Not found";

  useEffect(() => {
    getCommDetails();
  }, []);

  if (!commName.match(regex) || invalidAccess)
    return <NotFoundPage message={invalidAccess || "Community not found"} />;

  const profileDimensions = ["150px", "150px", "-75px"];
  const profileMarginTop = ["-180px", "-80px"];
  const commTitleName = "My Community";

  const commURL = "membeed.com" + validatedName;

  const commTitleSizes = {
    xsmall: "medium",
    small: "medium",
    medium: "large",
    large: "6xl",
  };
  const jhd = " KJ";

  const cards = [
    {
      // cardProfilePic abhi_needToDo
      cardColor: "black",
      cardTitle: "My Free Membership",
      cardCurrency: "USD",
      cardAmount: "0",
      cardPeriod: "monthly",
      cardPerkPoints: [
        "Every month, you have the chance to dial into My Funny Happy Hour, an exclusive hangout where My Funny fields calls and chats with y’all. ",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
      ],
    },
    {
      // cardProfilePic abhi_needToDo
      cardColor: "blue",
      cardTitle: "My Funny Membership",
      cardCurrency: "USD",
      cardAmount: "9",
      cardPeriod: "monthly",
      cardPerkPoints: [
        "Every month, you have the chance to dial into My Funny Happy Hour, an exclusive hangout where My Funny fields calls and chats with y’all. ",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
      ],
    },
    {
      // cardProfilePic abhi_needToDo
      cardColor: "blue",
      cardTitle: "My Premium Membership",
      cardCurrency: "USD",
      cardAmount: "99",
      cardPeriod: "yearly",
      cardPerkPoints: [
        "Every month, you have the chance to dial into My Funny Happy Hour, an exclusive hangout where My Funny fields calls and chats with y’all. ",
        "Every month, you get a recording of My Funny Happy Hour as an ad-free video and MP3.",
      ],
    },
  ];

  const openDropBox = () => {
    setDropBoxOpen(true);
  };

  const closeDropBox = () => {
    setDropBoxOpen(false);
  };
  const saveThePage = (event) => {
    // Logic for saving all the data
    // event.preventDefault();
    // const form = formRef.current;
    // if (form) {
    //   console.log('Form found eeehuuuuu' );
    //   form.submit(); // Submit the form using the ref

    // } else {
    //   console.error('Form ref not found');
    // }
    // console.log("save page clicked");
    setApiNote();
    const updatedPageDetails = {
      nameLink: window.location.pathname.slice("/edit".length).trim(),
      basicDetails: {
        commTitleName: titleForm.titleName,
        commTitleColor: commTitleColor,
        commTitleBGColor: commPageColor,
        commProfilePic: profileURL,
      },
      plans: commPlans,
      checkoutDetails: {
        preCheckoutUserInputs: {
          basicDetails: ["Name", "Number"],
          socialIds: platforms,
          otherId: platforms.includes("Other") ? otherId : "",
        },
        postCheckoutDetails: checkPostFormData,
      },
    };
    // console.log(updatedPageDetails);
     
    const firstError = validateCommunityData(updatedPageDetails) || validateMembercount(updatedPageDetails);
    console.error("Formerror : ", firstError);
    if (firstError) {
      // display errors
      setFormError(firstError || "Incomplete Community Details");
      setTimeout(() => {
        handleCloseNotification();
        setFormError();
      }, 8000);
      return;
    } else {
      //No errros
      putCommDetails(updatedPageDetails);
    }
    // console.log("Saved all the data");
  };

  const handleImageChange = async(event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    const sizeLimit = 1024 * 1000 * 2; // 100 KB limit

    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (file && allowedTypes.includes(file.type)) {

      // try to compress the image 
      // Proceed with compressing and processing the image
      const options = {
        maxSizeMB: 0.05, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1
        maxWidth: 360, // the max width of the output image, defaults to 1920px
        maxHeight: 360, // the max height of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
      }
      

      // const compress = new Compress();
      // const result = await compress.compress([file], options);
      // const img = result[0];
      // const base64str = img.data;
      // const imgExt = img.ext;
      if(file.size > sizeLimit){
        try {
          file = await imageCompression(file, options);
                  
        // console.log("File size is : ", file.size, file.size/1024000, "mb" );
        if (file.size > sizeLimit) {
          // Handle the case where the file is too large
          setFormError(`File size exceeds the limit of 100 KB.`);
          return;
        }
        } catch (error) {
          setFormError("error in compression");
          return;
        }

      }

      reader.onloadend = () => {
        setProfileURL(reader.result);
      };

      // console.log("After file compresssion size is : ", file.size,sizeLimit, file.size/1024000, "mb" );

      if (file && file.size < sizeLimit) {
        // console.log("seting File size is : ", file.size, file.size/1024000, "mb" );

        reader.readAsDataURL(file);
        setFormError();
        // saveThePage();
      }
    }
    else {
      setFormError("Invalid image");
    }
  };

  const removePic = () => {
    setProfileURL(backgroundImageUrl);
  };

  const getCommTitleSize = (size) => {
    // Based on size return title Size
    // Default value

    // if (typeof size !== 'string' || size !== undefined || size !== null) return defaultSize;
    if (size === "xsmall") {
      return "25px";
    } else if (size === "small") {
      return "45px";
    } else if (size === "medium") {
      return "65px";
    } else if (size === "large") {
      return "85px";
    } else if (size === "xlarge") {
      return "85px";
    } else {
      return "12px"; // Default font size
    }
  };

  const handleCloseNotification = () => {
    setFormError(null); // Clear error state on notification close
  };

  if (isPageLoading) return <PageLoader />;

  return (
    <Box>
      {formError && ( // Show notification only if there's an error
        <Box align="center">
          <Notification
            toast={{
              autoClose: true,
              position: "bottom-right",
            }}
            title="Error : "
            color="red"
            status="normal"
            message={<Text color="red">{formError}</Text>}
            time={10000}
            onClose={handleCloseNotification}
          />
        </Box>
      )}
      {apiNote && ( // Show notification only if there's an error
        <Box align="center">
          <Notification
            toast={{
              autoClose: true,
              position: "bottom-right",
            }}
            title="Note : "
            status="info"
            message={<Text color="green">{apiNote}</Text>}
            time={7000}
            onClose={handleCloseNotification}
          />
        </Box>
      )}
      <PageHeader
        title={"Edit Page"}
        margin={{ left: "medium" }}
        subtitle={
          <Anchor
            href={window.location.origin + validatedName}
            target="_blank"
            label={"Preview Link"}
            style={{ fontSize: "medium", fontWeight: "normal" }}
          />
        }
        actions={
          <Button
            tip="Saves all the records"
            secondary
            margin="small"
            label="Save"
            busy={isButtonLoading}
            onClick={saveThePage}
          />
        }
      ></PageHeader>
      <Box
        style={{
          maxWidth: "100vw",
          minWidth: "100vw",
        }}
      >
        <Box
          style={{
            minHeight: "300px",
            minWidth: "100vw",
          }}
        >
          <Box
            style={{
              height: "250px",
              minWidth: "100vw",
            }}
            background={{
              color: commPageColor,
              size: "cover",
            }}
            justify="center"
            align="center"
          >
            <Box
              alignSelf="start"
              pad="medium"
            // border={{ color: 'white', size: 'medium', style:"solid" }}
            // round="medium"
            >
              <Form
                value={titleForm}
                onChange={(value) => {
                  setTitleForm(value);
                }}
              >
                <FormField
                  name="titleName"
                // onChange={(value) => {
                //   setTitleForm(value);
                // }}
                >
                  <TextInput
                    // value={commTitleName + size}
                    required
                    name="titleName"
                    style={{ color: commTitleColor }}
                    size={getCommTitleSize(size)}
                    weight="300"
                    maxLength={24}
                  ></TextInput>
                </FormField>
              </Form>
            </Box>
          </Box>

          <Box
            // fill
            alignSelf="center"
            style={{
              height: profileDimensions[0],
              width: profileDimensions[1],
              marginTop: profileDimensions[2],
            }}
            border={{ color: "white", size: "medium", style: "solid" }}
            round="medium"
            background={{
              image: `url(${profileURL})`,
              size: "cover",
            }}
            onClick={openDropBox}
          />
        </Box>
        <Box justify="center" align="center">
          {(dropBoxOpen) && (
            <Layer onClickOutside={closeDropBox} onEsc={closeDropBox}>
              <FileInput
                // style={{
                //         fontWeight:"normal"
                //     }}
                maxSize={100}
                messages={{
                  browse: "Community Profile Pic",
                  dropPrompt: "Select your ",
                  // dropPromptMultiple: "Drop files here or",
                  // files: ".",
                  remove: "Remove",
                  // removeAll: ".",
                  maxFile: "Attach a maximum of {max} files only."
                }}
                // disabled={true}
                // confirm remove is not really needed as of now
                // confirmRemove={({ onConfirm, onCancel }) => (
                //   <Layer onClickOutside={onCancel} onEsc={onCancel}>
                //     <Box pad="medium" gap="medium">
                //       Are you sure you want to delete this file?
                //       <Box
                //         direction="row"
                //         align="center"
                //         justify="end"
                //         gap="small"
                //       >
                //         <Button label="Cancel" onClick={onCancel} />
                //         <Button
                //           label="Delete file"
                //           onClick={() => {
                //             onConfirm();
                //             removePic();

                //           }}
                //           secondary
                //         />
                //       </Box>
                //     </Box>
                //   </Layer>
                // )}
                multiple={false}
                onChange={handleImageChange}
              ></FileInput>
            </Layer>
          )}

          <Box direction="row" margin="small" size="medium" gap="medium">
            {/* <Form> */}
            <ColorPicker
              label="Choose Your Title Color"
              color={commTitleColor}
              setColor={setCommTitleColor}
            />
            <ColorPicker
              label="Choose Your Background Color"
              color={commPageColor}
              setColor={setCommPageColor}
            />
            {/* </Form> */}
          </Box>
        </Box>
      </Box>
      {/* Do changes to as thought  */}
      <SubcriptionForms
        formRef={formRef}
        plans={commPlans}
        setFormError={setFormError}
        setPlans={setCommPlans}
        fecthedPageDetails={pageDetails}
      />

      <SubscriberForm
        basicNeeds={basicNeeds}
        setBasicNeeds={setBasicNeeds}
        platforms={platforms}
        setPlatforms={setPlatforms}
        otherId={otherId}
        setOtherId={setOtherId}
        checkPostFormData={checkPostFormData}
        setCheckPostFormData={setCheckPostFormData}
      />
      <Button
        alignSelf="center"
        tip="Saves all the records"
        secondary
        style={{ maxWidth: "311px" }}
        margin="small"
        label="Save"
        busy={isButtonLoading}
        onClick={saveThePage}
      />
    </Box>
  );
};

export default CommunityEditPage;

