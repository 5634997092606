import React from 'react';
import { Layer, Box, Button, Text } from 'grommet';
import PropTypes from 'prop-types';

// Define the enum values
const MessageType = {
  CRITICAL: 'Critical',
  NORMAL: 'Normal',
  SUCCESS: 'Success',
};

const AlertContinue = ({ opMessage, messageType, onClose, callback, optional_params }) => {
  // Determine the background color based on the message type
  const getBackgroundColor = () => {
    switch (messageType) {
      case MessageType.CRITICAL:
        return 'darkred';
      case MessageType.SUCCESS:
        return 'darkgreen';
      default:
        return 'darkorange';
    }
  };

  return (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}
    >
      <Box pad="medium" gap="small" width="medium">
        <Text>You are trying to {opMessage} {optional_params}</Text>
        <Text color={getBackgroundColor()} >Do you want to continue?</Text>
        <Box direction="row" justify="end" margin={{ top: 'medium' }}>
            <Button label="cancel" onClick={onClose}  />
          <Button label="Continue" onClick={callback} secondary />
        </Box>
      </Box>
    </Layer>
  );
};

AlertContinue.propTypes = {
  opMessage: PropTypes.string.isRequired,
  messageType: PropTypes.oneOf(Object.values(MessageType)).isRequired,
  callback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlertContinue;
