import React, { useState } from "react";
import { Layer, Box, TextInput, Button, Text, Form, FormField } from "grommet";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import FormFieldError from "./formFieldError";

const AskCommNameLayer = ({ setIsAskNameOpen }) => {
  const fixedLink = "https://membeed.com/";
  const [communityForm, setCommunityForm] = useState({
    communityPage: "",
    communityLink: fixedLink + "",
  });
  const [errorMessage, setErrorMessage] = useState();

  // const commNameValidation = (inputValue) => {
  //     const pattern = /^[a-zA-Z0-9_]*$/; // Alphanumeric characters and underscore only
  //     // commName.match(regex)
  // if (!inputValue.match(pattern)) {
  //     return 'Only alphanumeric characters and underscores are allowed.';
  // }
  // return undefined;
  // };

  const [isPageLoading, setIsPageLoading] = useState(false);
  // abhi_BackendAPI
  const { getAccessTokenSilently } = useAuth0();
  const getPageStatusAPI = async (name) => {
    var result = "Server Error";
    setIsPageLoading(true);
    const queryObject = {
      query: "status",
    };

    const queryString = new URLSearchParams(queryObject).toString();

    const commURL =
      process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_COMM_SUBURL;

    const fullURL = commURL + "/" + name + "?" + queryString;
    console.log("full url : ", fullURL);

    const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fullURL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    };

    
    try {
      const response = await axios.request(config);
      if (response) {
        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }
        const response_str = response.data;
        if (!response_str) {
          throw new Error("invalid data fetched");
        }
        // console.log("Status of comm fetched : ", response_str);
        result = response_str;
      } else {
        throw new Error("No response");
      }
    } catch (error) {
      // console.log(error);
    }
    setIsPageLoading(false);
    return result;
  };

  const commNameValidation = [
    {
      regexp: new RegExp(`^${fixedLink}[a-zA-Z0-9_]*$`),
      message: "Enter a valid name",
      status: "error",
    },
    (communityLink) => {
      if (communityLink === fixedLink) return "Cant be empty";
      return undefined;
    },
  ];

  const navigate = useNavigate();

  const verifyName = async (name) => {
    if (!name || name.length == 0) return false;

    return true;
  };

  const submitName = async () => {
    // submit Name;
    // take to edit community
    // Validate the name if it is taken or not
    const name = communityForm.communityLink.slice(fixedLink.length).trim();

    if (!verifyName(name)) return; //invalid input
    var result = await getPageStatusAPI(name);
    // console.log("From api : ", result);
    if (result === "Active") {
      //cannot use the name}
      setErrorMessage("Name Already Taken.");
      return;
    }
    else if (result === "Server Error") {
      setErrorMessage("Server Error");
      return;
     }
    else {
      setErrorMessage();
    }
    goToEditCommunity(name);
    // window.alert("Going to name ", result);
  };

  const goToEditCommunity = (name) => {
    navigate(
      "/edit/" + communityForm.communityLink.substring(fixedLink.length)
    );
  };

  //   const handleInputChange = event => {
  //     setCommunityPage(event.target.value);
  //     };

  const closeLayer = () => {
    setIsAskNameOpen(false);
  };

  const handleFormSubmit = () => {
    // Basic sanity check for input
    // if (!communityPage.trim()) {
    //   setErrorMessage('Please enter a community page URL.');
    //   return;
    // }

    closeLayer();
    // Perform additional sanity checks if needed

    // If all checks pass, close the layer and do further processing
  };

  return (
    <Layer onEsc={closeLayer} onClickOutside={closeLayer}>
      <Box pad="medium" gap="medium" width="medium">
        <Text alignSelf="center" size="large">
          Enter Community Details
        </Text>
        <Form
          value={communityForm}
          onChange={(value) => {
            if (value.communityLink.length < fixedLink.length) return;
            setCommunityForm(value);
          }}
          onSubmit={submitName}
          method="post"
          validate="submit"
        >
          {/* <FormField
                      name='communityPage'
                      label='Community Name'
                  >
                      <TextInput name="communityPage" placeholder="My Community"/>
                  </FormField> */}

          <FormField
            name="communityLink"
            label="Community Page"
            validate={commNameValidation}
          >
            <TextInput
              name="communityLink"
              placeholder="my_discord1"
              required
            />
          </FormField>
          {errorMessage && <FormFieldError error={errorMessage} />}

          <Box direction="row" justify="center" pad="medium">
            {/* onClick={closeLayer} */}
            <Button
              secondary
              type="submit"
              label="Submit"
              busy={isPageLoading}
            />
          </Box>
        </Form>
      </Box>
    </Layer>
  );
};

export default AskCommNameLayer;
