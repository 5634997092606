import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grommet,
  Header,
  Text,
  Grid,
  Notification,
  ResponsiveContext
} from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment, useEffect, useState, useContext } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
import NotFoundPage from "../components/notFound";
import SubscriberCard from "../components/subscriberCard";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { PageLoader } from "../components/pageLoader";
import JoinCommunityForm from "../components/preCheckoutInput";
import PostPaymentPage from "../components/paymentPaid";
import { useNavigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";

const backgroundImageUrl =
  "https://res.cloudinary.com/ddordov7b/image/upload/v1718089844/image_placeholder_fx24u2.png";

const defaultDetails = {
  nameLink: window.location.pathname,
  basicDetails: {
    commTitleName: "Community Title",
    commTitleColor: "#ffffff",
    commTitleBGColor: "#031635",
    commProfilePic: backgroundImageUrl,
  },
  plans: [
    {
      planName: "Basic Plan",
      currency: "USD $",
      price: 10,
      period: "Monthly",
      points: ["Feature 1", "Feature 2"],
    },
  ],
  checkoutDetails: {
    preCheckoutUserInputs: {
      basicDetails: ["Name", "Number"],
      socialIds: [],
      otherId: "",
    },
    postCheckoutDetails: {
      isRedirect: true,
      redirectURI: "http://example.com/redirect",
      checkoutMsg: "Thank you for your purchase!",
    },
  },
  subscriberUsers: [],
};

const validateCommunityData = (data) => {
  const errors = [];
  if (
    !data.nameLink ||
    typeof data.nameLink !== "string" ||
    !data.nameLink.match(/^\/[a-zA-Z0-9_]*$/)
  ) {
    errors.push("nameLink is invalid.");
    return "Not a valid URL";
  }
  if (!data.basicDetails) {
    errors.push("basicDetails is required.");
    return "Not a valid title";
  }
  if (data.basicDetails) {
    if (!data.basicDetails.commTitleName) {
      errors.push("TitleName is required.");
      return "Title Name is required.";
    }
    if (!data.basicDetails.commTitleColor) {
      errors.push("commTitleColor is required.");
      return "Title Color is required.";
    }
    if (!data.basicDetails.commTitleBGColor) {
      errors.push("commTitleBGColor is required.");
      return "Title Background is required.";
    }
  }
  if (
    !data.plans ||
    !Array.isArray(data.plans) ||
    data.plans.length === 0 ||
    data.plans.length > 3
  ) {
    errors.push("Invalid plans");
    return "Invalid plans";
  }
  //  console.log("plan error check", data)
  // plan details error check 
  if (data.plans && Array.isArray(data.plans)) {
    for (let i = 0; i < data.plans.length; i++) {
      const plan = data.plans[i];
      if (!plan.planName || plan.planName === "") {
        return `Plan name is required for plan ${i + 1}.`;
      }
      if (!plan.currency || plan.currency === "") {
        return `Currency is required for plan ${i + 1}.`;
      }
      if (plan.price === null || plan.price === "") {
        return `Price is required for plan ${i + 1}.`;
      }
      if (!plan.period || plan.period === "") {
        return `Period is required for plan ${i + 1}.`;
      }

      if (
        !plan.points ||
        !Array.isArray(plan.points) ||
        plan.points.length === 0 ||
        plan.points.length > 5
      ) {
        return `Points are not valid for plan ${i + 1}.`;
      }
    }
  } else {
    errors.push("Not a valid plans array");
    return "Invalid Plans";
  }
  if (data.checkoutDetails) {
    if (!data.checkoutDetails.preCheckoutUserInputs) {
      errors.push("Pre checkout User Inputs is required.");
      return false;
    } else {
      if (
        !data.checkoutDetails.preCheckoutUserInputs.basicDetails ||
        !Array.isArray(
          data.checkoutDetails.preCheckoutUserInputs.basicDetails ||
          data.checkoutDetails.preCheckoutUserInputs.basicDetails.length === 0
        )
      ) {
        errors.push("invalid basicDetails");
        return "Select either Name or Number";
      }
      if (
        !data.checkoutDetails.preCheckoutUserInputs.socialIds ||
        !Array.isArray(data.checkoutDetails.preCheckoutUserInputs.socialIds)
      ) {
        errors.push("invalid socialIds");
        return "Invalid Social IDs";
      }
      if (
        (data.checkoutDetails.preCheckoutUserInputs.socialIds.includes(
          "Other"
        ) &&
          !data.checkoutDetails.preCheckoutUserInputs.otherId) ||
        typeof data.checkoutDetails.preCheckoutUserInputs.otherId !== "string"
      ) {
        errors.push("invalid otherId");
        return "Invalid Social ID (Other)";
      }
    }
    if (!data.checkoutDetails.postCheckoutDetails) {
      errors.push("postCheckoutMsg is required in checkoutDetails.");
      return false;
    } else {
      const { isRedirect, redirectURI, checkoutMsg } =
        data.checkoutDetails.postCheckoutDetails;
      if (typeof isRedirect !== "boolean") {
        errors.push("Invalid type for 'isRedirect'. Expected boolean.");
        return "Invalid type for 'Redirect to a Link'";
      }

      if (typeof redirectURI !== "string") {
        errors.push("Invalid type for 'redirectURI'. Expected string.");
        return "Invalid type for 'redirect URL'.";
      }

      if (typeof checkoutMsg !== "string") {
        errors.push("Invalid type for 'checkoutMsg'. Expected string.");
        return "Invalid type for 'checkoutMsg'.";
      }
    }
  } else {
    errors.push("No checkoutDetails");
    return "No checkoutDetails";
  }
  return null;
};

const CommunityPage = () => {
  const size = useContext(ResponsiveContext);
  const isMobile = size === "xsmall";
  // console.log("SISEESSEEEEe",size)
  //   const { getAccessTokenSilently } = useAuth0();
  // const { isAuthenticated } = useAuth0();

  // const [username, Setname] = React.useState('None')
  // let loginState = (isAuthenticated ?  "True" : "False" ); //Delete it after use

  // console.log(username);
  // return (
  //     <h1>{username}</h1>
  // );

  const curPath = window.location.pathname;

  const pattern = /^\/([a-zA-Z0-9_]+)(?:\/([^/]+))?(?:\/([^/]+))?(?:\/)?$/;  //  - /{commName}/{merchTxnId}/{userId}

  // Execute the pattern against the URL string
  const matched = curPath.match(pattern);

  var userObjectId = null;
  var merchantTransactionId = null;
  var commName = null;
  if (matched) {
    commName = matched[1];
    merchantTransactionId = matched[2] || null;
    userObjectId = matched[3] || null;
  }
  // const commName = window.location.pathname;
  // console.log("variables  : ", commName, userObjectId, merchantTransactionId);
  // const regex = /^\/[a-zA-Z0-9_]*$/;  // for just /telegram 
  var validatedName = null;
  if (commName) {
    validatedName = commName;
    //new regex directly takes care of first slash
  }


  const [serverErrorMsg, setServerErrorMsg] = useState();
  const [pageDetails, setPageDetails] = useState(defaultDetails);
  const [isPageLoading, setIsPageLoading] = useState();
  const [preCheckoutOpen, setPreCheckoutOpen] = useState(false);
  const [invalidPage, setInvalidPage] = useState(false);
  const [curSubscriberPreInputs, setCurSubscriberPreInputs] = useState();
  const [chosenPlan, setChosenPlan] = useState(0);
  const [amountPayable, setAmount] = useState(0);
  const [planType, setPlanType] = useState(0);
  const Navigate = useNavigate();

  const handleCloseNotification = () => {
    setServerErrorMsg();
  }

  const getPublicCommunityAPI = async () => {
    setIsPageLoading(true);
    const queryObject = {
      query: "details",
    };

    const queryString = new URLSearchParams(queryObject).toString();

    const commURL =
      process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_COMM_SUBURL;

    const fullURL = commURL + "/" + validatedName + "?" + queryString;
    // console.log("full url : ", fullURL);

    // const access_token = await getAccessTokenSilently();
    // console.log("access_token : ", access_token);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fullURL,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      var result;
      if (response) {
        if (response.status !== 200) {
          setInvalidPage(true);
          throw new Error("Failed to fetch data");
        } else {
          setInvalidPage(false);
          const response_json = response.data;
          if (!response_json) {
            throw new Error("invalid data fetched");
          }
          if (response_json === "Inactive") {
            // Not a valid page
            setInvalidPage(true);
          }
          // console.log("Status of comm fetched : ", response_json);
          const anyErrors = validateCommunityData(response_json);
          if (!anyErrors) {
            // valid comm details has come
            setPageDetails(response_json);
          }
        }
      } else {
        throw new Error("No response");
      }
    } catch (error) {
      // console.log(error);
    }
    setIsPageLoading(false);
  };

  const paymentHandler = async ({ userData, choosenPlanIndex }) => {
    let amount;
    if(planType == "Events"){
    // console.log("Display card info", cards[choosenPlanIndex])
    // console.log("payments page with plan : ", cards[choosenPlanIndex].cardAmount);
    // console.log("payment with discount", cards[choosenPlanIndex].cardDiscount);
    // console.log("payment with ",cards[choosenPlanIndex].cardDiscountMembers);
    // console.log("payment with ",cards[choosenPlanIndex].cardDiscountAvailable);
     // const someDummyUserObjId = "987834598354";
     // console.log("payments page with plan 3: ", cards[choosenPlanIndex].cardAmount);
     amount = cards[choosenPlanIndex].cardAmount 
     const discountedAmount = (amount - (amount * cards[choosenPlanIndex].cardDiscount / 100)).toFixed(2)
     if(cards[choosenPlanIndex].cardDiscountMembers>0){
       amount = discountedAmount
     }
    }else{
      amount = userData.DonationAmount; // main number change
    }
    setIsPageLoading(true);
 
    const payload = {
      redirectURL: window.location.origin + "/" + validatedName,
      amount: amount,
      user: userData,
      community: validatedName,
      discount:{
        isdiscountAvailable: cards[choosenPlanIndex].cardDiscountAvailable,
        discountMembers: cards[choosenPlanIndex].cardDiscountMembers
      },
      planObjectId: cards[choosenPlanIndex].cardPlanObjectId
    };
    
    // console.log("payload print",payload)
  
    const paymentURL =
      process.env.REACT_APP_BACKEND_URL +
      process.env.REACT_APP_PHONEPE_URL +
      "/collect";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: paymentURL,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload
    };
    // console.log("payments page with plan 6: ", cards[choosenPlanIndex].cardAmount);

    try {
      const response = await axios.request(config);
      if (response) {
        if (response.status === 200) {
          // console.log("Redirect to Phonpe");
          const responseData = response.data;
          // console.log(responseData);
          if (
            responseData 
          ) {
            if(responseData.data && responseData.data.instrumentResponse &&
              responseData.data.instrumentResponse.redirectInfo &&
              responseData.data.instrumentResponse.redirectInfo.url){
            const phonepe_paymentURL =
              responseData.data.instrumentResponse.redirectInfo.url;
              // console.log("phonpe callbackURL : ", phonepe_paymentURL);
              // window.alert("Redirecting to Phonpe");
              window.location.href = phonepe_paymentURL;
              }
              else if(responseData.freeUser === true && responseData.userObjectID){
                // console.log("coming to paid");
                Navigate("/paid", {state : {
                  commName: validatedName, userObjectID: userObjectId, merchantTransactionId: "", freeUser : true
                }});
              }
              else{
                throw new Error("invalid pp response");
              }
          }

        }
      } else {
        throw new Error("No Response ERROR");
      }
    } catch (error) {
      // console.log(error);
      setIsPageLoading(false);
    }
  };

  const cards = pageDetails.plans.map((plan, index) => {
    // console.log("Bg color", pageDetails.basicDetails.commTitleBGColor)
    // console.log("Title color", pageDetails.basicDetails.commTitleColor)
    // console.log("plan output", plan)
    return {
      // cardProfilePic abhi_needToDo
      cardColor: pageDetails.basicDetails.commTitleBGColor,
      cardTitle: plan.planName,
      cardType: plan.planType,
      cardCurrency: plan.currency,
      cardAmount: plan.price,
      cardtotalSeats: plan.totalSeats,
      cardDiscount: plan.discountPercentage || 0 ,
      cardDiscountMembers: plan.discountMembers || 0,
      cardDiscountAvailable: plan.discountAvailable,
      cardPeriod: plan.period,
      cardPerkPoints: plan.points,
      cardPlanObjectId: plan.planObjectId,
      cardAvailableSeats: plan.availableSeats
    };
  });

  const basicNeeds = [
    ...pageDetails.checkoutDetails.preCheckoutUserInputs.basicDetails,
    ...pageDetails.checkoutDetails.preCheckoutUserInputs.socialIds,
    pageDetails.checkoutDetails.preCheckoutUserInputs.otherId,
  ];

  // console.log(basicNeeds);

  const cardJoinHandler = async (index) => {
    if (index < 0 || index >= cards.length) {
      // console.log("Invalid plan clicked: index ", index);
      setPreCheckoutOpen(false);
      setChosenPlan(0);
      return;
    }
    
    // Calculate amount and discount
    const selectedPlan = cards[index];
    var amountPayablediscount = selectedPlan.cardAmount;
    if(selectedPlan.cardDiscountAvailable)
      amountPayablediscount = (selectedPlan.cardAmount - (selectedPlan.cardAmount * cards[index].cardDiscount / 100)).toFixed(2)
    // const discount = {
    //   isdiscountAvailable: selectedPlan.cardDiscountAvailable,
    //   discountMembers: selectedPlan.cardDiscountMembers,
    // };
    
    setPreCheckoutOpen(true);
    setChosenPlan(index);
    setCurSubscriberPreInputs({}); // Reset user inputs
    setAmount(amountPayablediscount); // Update amount state if necessary
   // setDiscount(amountPayable); // Update discount state if necessary
    console.log("I am printing", cards[index].cardType)
    setPlanType(cards[index].cardType);
    // console.log("JOIN plan index: ", index);

  };


  const preCheckoutSumitHandle = async (userData) => {
    setCurSubscriberPreInputs(userData);
    console.log("Donation data to be submitted : ", userData);
    // Ask stripe to take the payment
    // console.log(
    //   "WIll go to STRIPE and charge : ",
    //   pageDetails.plans[chosenPlan].price,
    //   " ",
    //   pageDetails.plans[chosenPlan].currency
    // );
    // const user_obj_id =  await subscribeToCommunity(data);
    await paymentHandler({ userData, choosenPlanIndex: chosenPlan });
    setIsPageLoading(false);
  };

  // const subscribeToCommunity = async (data) => {
  //   setIsPageLoading(true);
  //   // console.log("User data to be pushed : ", data);
  //   let payload = JSON.stringify(data);


  //   const subscribeToCommURL =
  //     process.env.REACT_APP_BACKEND_URL +
  //     process.env.REACT_APP_COMM_SUBURL +
  //     "/" + validatedName + "/users";


  //   let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: subscribeToCommURL,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data: payload
  //   };

  //   try {
  //     axios.request(config)
  //       .then((response) => {
  //         console.log(JSON.stringify(response.data));
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }


  // }

  useEffect(() => {
    if (validatedName && !userObjectId && !merchantTransactionId) {
      getPublicCommunityAPI();
    }

  }, []);

  const backgroundImageUrl =
    "https://res.cloudinary.com/ddordov7b/image/upload/v1717929099/membeed_hphikh.png";

  const profileDimensions = ["150px", "150px"];
  const profileMarginTop = "-180px";
  const commTitleName = "My Community";
  const commTitleColor = "white";

  
  if (isPageLoading) return <PageLoader />;
  // console.log("comname ; ", validatedName, !validatedName);
  if (!validatedName || invalidPage){
    return <NotFoundPage message="Community not found" />;}

  // Will be returned by PG after payment for the first time, so process postPayment details
  if (validatedName && userObjectId && merchantTransactionId) {
    // /
    // return <PostPaymentPage commName={validatedName} userObjectID={"66890b0a1eb7e08e86103ed8"} merchantTransactionId={"15cwerabsly9wrwp1/"}/>
    // console.log("postPayments");
    Navigate("/paid", {state : {
      commName: validatedName, userObjectID: userObjectId, merchantTransactionId: merchantTransactionId
    }});
    // console.log("Bg color", pageDetails.basicDetails.commTitleBGColor)
    // return <PostPaymentPage commName={validatedName} userObjectID={userObjectId} merchantTransactionId={merchantTransactionId}/>
    // console.log("Amount fetched", amountPayable)
  }
  return (
    <Box flex fill margin={{bottom : "medium"}}>
      <Box fill >
        <Box
          fill
          style={{
            minHeight: "250px",
            minWidth: "100vw",
          }}
          background={{
            color: pageDetails.basicDetails.commTitleBGColor,
          }}
        />
        <Box
          alignSelf="start"
          style={{
            // minHeight: profileDimensions[0],
            // minWidth: profileDimensions[1],
            // maxHeight: profileDimensions[0],
            // maxWidth: profileDimensions[1],
            marginTop: "-200px",
            marginBottom: "150px",
          }}
          pad="medium"
          fill
        // border={{ color: 'white', size: 'medium', style:"solid" }}
        // round="medium"
        >
          <Text
            color={pageDetails.basicDetails.commTitleColor}
            size={isMobile ? "40px" : "80px"}
            
            weight="normal"
            truncate={true}
          >
            {pageDetails.basicDetails.commTitleName.length > 30
              ? pageDetails.basicDetails.commTitleName.substring(0, 12) + "..."
              : pageDetails.basicDetails.commTitleName}
          </Text>
        </Box>
        <Box
          fill
          alignSelf="center"
          style={{
            minHeight: profileDimensions[0],
            minWidth: profileDimensions[1],
            maxHeight: profileDimensions[0],
            maxWidth: profileDimensions[1],
            marginTop: isMobile ? "-120px" : profileMarginTop,
          }}
          border={{ color: "white", size: "medium", style: "solid" }}
          round="medium"
          background={{
            image: `url(${pageDetails.basicDetails.commProfilePic})`,
            size: "cover",
          }}
        />
      </Box>
      {/* <Box
        margin="medium"
        justify="center"
        align="center"
        gap="medium"
              direction="row-responsive"
              wrap
        // columns={{
        // count: cards.length,
        // size: ["auto", "auto"],
        // }}
      >
        {cards.map((card, index) => {
            
        })}
      </Box> */}
      <Box align="center" direction="row" justify="center" wrap>
        <Box gap="medium" direction="row-responsive">
          {cards.map((card, index) => (
            <SubscriberCard
              key={index}
              card={card}
              index={index}
              jointextColor={pageDetails.basicDetails.commTitleColor}
              joinBGColor={pageDetails.basicDetails.commTitleBGColor}
              cardClickHandler={() => cardJoinHandler(index)}
            />
          ))}
        </Box>
      </Box>
      {preCheckoutOpen && (
        <JoinCommunityForm
          community={pageDetails.basicDetails.commTitleName}
          planType={planType}
          basicNeeds={basicNeeds}
          amount={amountPayable}
          onSubmit={preCheckoutSumitHandle}
          onClose={() => setPreCheckoutOpen(false)}
          serverErrorMsg={serverErrorMsg}
        />
        )}
        
    </Box>
  );
};

export default CommunityPage;
