import { Box, Grommet, Header, ResponsiveContext, Text } from "grommet";
import { hpe } from "grommet-theme-hpe";

// import { Hpe, Chat, User, Notification, HelpOption, AppsRounded} from "grommet-icons";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Title from "../components/title";
import DashBoardLabels from "../components/dashboardLabels";
import ProfileLabel from "../components/profileLabel";
import ProfileDrop from "../components/profileDrop";
import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-react";

const NavBarPage = () => {
  // const { getAccessTokenSilently } = useAuth0();
  const { isAuthenticated } = useAuth0();

  const size = useContext(ResponsiveContext);  // screen size
  const isMobile = size === "xsmall";
  

  // const [username, Setname] = React.useState('None')
  // let loginState = (isAuthenticated ?  "True" : "False" ); //Delete it after use

  // console.log(username);
  // return (
  //     <h1>{username}</h1>
  // );

  const allowed_paths = [
    "/",
    "/dashboard",
    "/members",
    "/subscriptions",
    "/transactions",
    "/communities",
    "/analytics",
    "/profile",
    "/policies/tnc",
    "/policies/privacy",
    "/policies/refund",
    "/purchases"
    // abhi_check if_any_urls_to_add
  ]


  var curPath = window.location.pathname;
  curPath = curPath.length > 1 ? curPath.replace(/\/+$/, ""): curPath
  // console.log("currpath",curPath)
  const [display,setDisplay] = useState(false);

  useEffect(()=>{
    const tmpDisplay = allowed_paths.includes(curPath) || curPath.startsWith("/edit/")
    setDisplay(tmpDisplay)
  },[curPath,isAuthenticated])
  // console.log(display, "in navBarPage");
  
  if (!display) return <Fragment />;
  return (
    <Fragment>
          <Box
    style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1 }}
    color="white"
    background={{color: "white"}}
     >
      {/* <Header > */}
      <Header
        align="center"
        direction="row"
        flex={false}
        justify="between"
        gap="medium"
        fill="horizontal"
        pad={{vertical : isMobile ? "none" : "medium", horizontal: "small"}}
        elevation="3px"
        border={{ color: "border-weak", side: "bottom" }}
        style={{ boxShadow: "rgb(136, 136, 136) 0px 1px 1px" }}
        height="60px"
      >

        
        <Box align="center" justify="center" direction="row" gap="xlarge">
                  {/* Left Most Title */}
              <Title />
              {/* {isAuthenticated ? <DashBoardLabels /> : <p />} */}
            {/*  Dashboard if authed, */}
            {/* Stopping for yamini phonepe */}
          {/* {!isMobile && isAuthenticated && <DashBoardLabels />} */}
          {!isMobile && <DashBoardLabels />}
        </Box>


        {/* Project <prop - commID> !MVP */}

        {/* RightMost Profile or Login / Register */}
        {isAuthenticated ? <ProfileDrop/> : <ProfileLabel/>}
        
        
      </Header>
    </Box>
    <Box
    height="60px"
     >

    </Box>
    </Fragment>

  );
};

export default NavBarPage;
