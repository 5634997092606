import React, { useContext } from "react";
import { Box, Menu, ResponsiveContext } from "grommet";
import { Descending } from "grommet-icons";
import { useAuth0 } from "@auth0/auth0-react";

const ProfileDrop = () => {
  const { user, logout } = useAuth0();
  const { name } = user;

  const size = useContext(ResponsiveContext);  // screen size
  const isMobile = size === "xsmall";

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    // import { Box, Menu } from 'grommet';
    // import { Descending } from 'grommet-icons'; // Make sure to import your icon correctly
    
    <Box
      align="center"
      justify="center"
      background="background-contrast"
      round="full"
      pad="small"
      width={isMobile ? "36px" : "54px"}
      height={isMobile ? "36px" : "54px"}
      margin={{ right: isMobile ? "xsmall" : "small" }}
    >
      <Menu
        label={name.substring(0, 2).toUpperCase()}
        icon={<Descending style={{display:"none"}}/>}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width:isMobile ?"36px" : "54px",
          height: isMobile ?"36px" : "54px",
          fontWeight: "normal",
          fontSize: isMobile ? "16px" : "22px",
        }}
        dropAlign={{ right: "right", top: "bottom" }}
        items={[
          {
            label: "My Account",
            justify: "center",
            disabled: true,
            onClick: () => {},
          },
          { label: "Log out", justify: "center", onClick: handleLogout },
        ]}
      />
    </Box>
    
  );
};

export default ProfileDrop;
